import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Header from '../common/Header';
import DatePicker, {registerLocale} from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import './SelectDatePeople.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import BoyIcon from '@mui/icons-material/Boy';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';

registerLocale('ko', ko);

const SelectDatePeopleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 60px;
`;

const FormField = styled.div`
    margin-bottom: 1.5rem;
    width: 90%;
    max-width: 600px;
    align-content: center;
`;

const Label = styled.label`
    display: block;
    font-weight: bold;
    width: 100%;
    margin-left: 2rem;
    text-align: left;
    font-size: 25px;
    max-width: 600px;
`;

const CounterWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
`;

const PersonTypeWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 90%;
    max-width: 600px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 0.5rem;
`;

const PersonDetails = styled.div`
    flex-grow: 1;
`;

const CounterButton = styled.button`
    background-color: transparent;
    color: ${({type}) => (type === 'increment' ? '#0096FF' : '#D32F2F')}; // 플러스 버튼: 초록색, 마이너스 버튼: 빨간색
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const CounterValue = styled.span`
    font-size: 1.5rem;
    margin: 0 1rem;
    width: 1rem;
`;

const Button = styled.button`
    padding: 1rem;
    border: none;
    border-radius: 0;
    font-size: 1rem;
    cursor: pointer;
    background-color: #0096FF;
    color: white;
    width: 100%;
    max-width: 600px;
    position: fixed;
    bottom: 0;
    align-content: center;

    &:hover {
        background: #357ae8;
    }

    &:disabled {
        background-color: #ccc;
        color: #666;
    }
`;

const SelectedDates = styled.div`
    margin-top: 1rem;
    font-size: 1rem;
    color: #555;
`;

const SelectDatePeople = () => {
    const navigate = useNavigate();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [numAdults, setNumAdults] = useState(1);
    const [numChildren, setNumChildren] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [buttonText, setButtonText] = useState('날짜와 인원을 선택해 주세요');

    useEffect(() => {
        if (startDate && endDate && numAdults >= 1) {
            const nights = (endDate - startDate) / (1000 * 60 * 60 * 24);
            const days = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;
            let buttonContent = `성인 ${numAdults}명, `;
            if (numChildren > 0) {
                buttonContent += `어린이 ${numChildren}명, `;
            }
            buttonContent += `${nights}박, ${days}일 확인했습니다!`;
            setButtonText(buttonContent);
            setIsButtonEnabled(true);
        } else {
            setButtonText('날짜와 인원을 선택해 주세요');
            setIsButtonEnabled(false);
        }
    }, [startDate, endDate, numAdults, numChildren]);

    const handleNext = () => {
        if (isButtonEnabled) {
            navigate('/selection', {
                state: {
                    startDate,
                    endDate,
                    numAdults,
                    numChildren,
                },
            });
        }
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleIncrement = (setter, value) => {
        if (value < 9) {
            setter(value + 1);
        } else {
            setShowModal(true);
        }
    };

    const handleDecrement = (setter, value, min) => {
        if (value > min) {
            setter(value - 1);
        }
    };

    return (
        <>
            <Header pageTitle="여행 기본 정보 입력"/>
            <SelectDatePeopleWrapper>
                <Label>여행 날짜</Label>
                <FormField>
                    <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                        minDate={new Date()}
                        locale="ko"
                        calendarContainer={(props) => (
                            <div className="react-datepicker">{props.children}</div>
                        )}
                    />
                </FormField>
                {startDate && endDate && (
                    <SelectedDates>
                        <p>선택된 날짜: {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}</p>
                    </SelectedDates>
                )}
                <Label>인원</Label>
                <PersonTypeWrapper>
                    <BoyIcon fontSize="large" style={{marginRight: '1rem'}}/>
                    <PersonDetails>
                        <p><strong>성인</strong></p>
                        <p>13세~64세</p>
                    </PersonDetails>
                    <CounterWrapper>
                        <CounterButton type="decrement" onClick={() => handleDecrement(setNumAdults, numAdults, 1)}>
                            <RemoveCircleOutlineIcon fontSize="large"/>
                        </CounterButton>
                        <CounterValue>{numAdults}</CounterValue>
                        <CounterButton type="increment" onClick={() => handleIncrement(setNumAdults, numAdults)}>
                            <AddCircleOutlineIcon fontSize="large"/>
                        </CounterButton>
                    </CounterWrapper>
                </PersonTypeWrapper>
                <PersonTypeWrapper>
                    <EscalatorWarningIcon fontSize="large" style={{marginRight: '1rem'}}/>
                    <PersonDetails>
                        <p><strong>어린이</strong></p>
                        <p>12세 이하</p>
                    </PersonDetails>
                    <CounterWrapper>
                        <CounterButton type="decrement" onClick={() => handleDecrement(setNumChildren, numChildren, 0)}>
                            <RemoveCircleOutlineIcon fontSize="large"/>
                        </CounterButton>
                        <CounterValue>{numChildren}</CounterValue>
                        <CounterButton type="increment" onClick={() => handleIncrement(setNumChildren, numChildren)}>
                            <AddCircleOutlineIcon fontSize="large"/>
                        </CounterButton>
                    </CounterWrapper>
                </PersonTypeWrapper>
                <Button onClick={handleNext} disabled={!isButtonEnabled}>{buttonText}</Button>
            </SelectDatePeopleWrapper>
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <button className="modal-close" onClick={() => setShowModal(false)}>X</button>
                        <p>최대 9인까지 설정 가능합니다.</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default SelectDatePeople;

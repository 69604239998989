import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';  // Firestore 설정

const ChallengeContainer = styled.div`
  padding: 1rem;
  background-color: white;
  border-radius: 10px;
  width: calc(100% - 2rem);
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);  // 가로 2칸
  grid-template-rows: repeat(4, auto);  // 세로 4칸 (자동 크기)
  gap: 1rem;  // 카드 간격
`;

const ChallengeCard = styled.div`
  background-color: white;
  border-radius: 10px;
  text-align: center;
  font-size: 1rem;
  padding: 1rem;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;

  &:hover {
    transform: scale(1.05);
  }
`;

const BackButton = styled(ChallengeCard)`  // ChallengeCard 스타일을 그대로 사용
  background-color: #f44336;
  color: white;
  
  &:hover {
    background-color: #e53935;
    transform: scale(1.05);
  }
`;

const ChallengeSelect = ({ onSelectChallenge, onActivitySelect }) => {  // onActivitySelect를 부모로부터 전달받음
  const [themes, setThemes] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [activities, setActivities] = useState([]);

  // Firestore에서 테마 데이터 불러오기
  useEffect(() => {
    const fetchThemes = async () => {
      const themesCollection = collection(db, 'themes');
      const themeSnapshot = await getDocs(themesCollection);
      const themeList = themeSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setThemes(themeList);
    };

    fetchThemes();
  }, []);

  // 선택된 테마에 따라 활동 목록 불러오기
  const fetchActivities = async (themeId) => {
  const activitiesCollection = collection(db, `themes/${themeId}/activities`); // 테마의 서브컬렉션 참조
  const activitySnapshot = await getDocs(activitiesCollection); // 서브컬렉션의 모든 문서 가져오기
  const activityList = activitySnapshot.docs.map(doc => ({
    id: doc.id,           // 활동의 Firestore 문서 ID
    ko: doc.data().ko,    // 각 문서의 ko 필드 값 추가
    ...doc.data(),        // 나머지 활동 데이터 병합
  }));
  setActivities(activityList); // 상태 업데이트
};


  // 테마 선택 시 활동 목록 불러오기
  const handleSelectTheme = (themeId) => {
    setSelectedTheme(themeId);
    fetchActivities(themeId);
  };

  // 테마에 대한 전체 리뷰 보기
  const handleViewThemeReviews = (themeId) => {
    onActivitySelect(themeId, null); // 전체 테마 리뷰 보기
  };


  // 활동 선택 시 해당 활동에 대한 리뷰 보기
  const handleActivitySelect = (activityId) => {
    onActivitySelect(selectedTheme, activityId);  // 활동에 대한 리뷰 보기
  };

  // 테마 목록으로 돌아가기
  const handleBackToThemes = () => {
    setSelectedTheme(null);
    setActivities([]);
    onSelectChallenge(null);  // 부모 컴포넌트에 선택 초기화 전달
    onActivitySelect(null);  // 활동 선택도 초기화
  };

  return (
    <>
      <ChallengeContainer>
        {selectedTheme === null ? (
          themes.map(theme => (
            <ChallengeCard key={theme.id} onClick={() => handleSelectTheme(theme.id)}>
              <h2>{theme.id}</h2>
            </ChallengeCard>
          ))
        ) : (
          <>
            <BackButton onClick={handleBackToThemes}>뒤로가기</BackButton>

            {/* 테마 전체 리뷰 보기 카드 추가 */}
            <ChallengeCard onClick={() => handleViewThemeReviews(selectedTheme)}>
              <h2>테마 전체에 대한 리뷰 보기</h2>
            </ChallengeCard>

            {/* 선택된 테마의 활동 목록 */}
            {activities.map(activity => (
              <ChallengeCard key={activity.ko} onClick={() => handleActivitySelect(activity.ko)}>
                <h2>{activity.ko}</h2>
              </ChallengeCard>
            ))}
          </>
        )}
      </ChallengeContainer>
    </>
  );
};

export default ChallengeSelect;

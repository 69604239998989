import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {doc, getDoc, setDoc, updateDoc, query, collection, where, getDocs} from 'firebase/firestore';
import {db} from '../../firebase';
import {useAuth} from '../../context/AuthContext';

const LLMDetailedInfo = ({
                             userID,
                             docId,
                             selectedActivity,
                             selectedRegion,
                             startDate,
                             endDate,
                             hotelName,
                             numAdults,
                             numChildren,
                             toursightData = [],
                         }) => {
    const {user, loading: authLoading} = useAuth();
    const [tripDescription, setTripDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [descriptionExists, setDescriptionExists] = useState(false);
    const [canClick, setCanClick] = useState(true);
    const [clickCount, setClickCount] = useState(0);

    const titleContainerStyle = {
        width: '100%',
        maxWidth: '1200px',
        margin: '1rem',
        textAlign: 'left',
    };

    const titleStyle = {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: '#333',
    };

    const containerStyle = {
        width: 'calc(100% - 2rem)',
        maxWidth: '1200px',
        padding: '1rem',
        backgroundColor: '#ffffff',
        borderRadius: '12px',
        margin: '1rem',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        border: '1px solid #e0e0e0',
        boxSizing: 'border-box',
        textAlign: 'center',
    };

    const contentStyle = {
        fontSize: '1.1rem',
        color: '#444',
        lineHeight: '1.6',
        textAlign: 'left',
    };

    const buttonStyle = {
        width: '100%',
        padding: '1rem',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        backgroundColor: canClick && user ? '#007bff' : '#cccccc',
        color: '#ffffff',
        border: 'none',
        borderRadius: '8px',
        cursor: canClick && user ? 'pointer' : 'not-allowed',
    };

    // Firestore에서 클릭 횟수 확인
    const checkClickLimit = async () => {
        if (!user) return; // 로그인하지 않은 경우 실행하지 않음
        try {
            const q = query(collection(db, 'users'), where('uid', '==', user.uid));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                const userData = userDoc.data();
                const today = new Date().toISOString().slice(0, 10);

                if (userData.GPT && userData.GPT[today]) {
                    setClickCount(userData.GPT[today]);
                    if (userData.GPT[today] >= 5) {
                        setCanClick(false);
                    }
                }
            } else {
                console.log('해당 uid를 가진 사용자가 없습니다.', user.uid);
            }
        } catch (error) {
            console.error('Error checking click limit:', error);
        }
    };

    // 클릭 횟수 업데이트
    const updateClickCount = async () => {
        if (!user) return; // 로그인하지 않은 경우 실행하지 않음
        try {
            const q = query(collection(db, 'users'), where('uid', '==', user.uid));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                const userRef = userDoc.ref;
                const userData = userDoc.data();
                const today = new Date().toISOString().slice(0, 10);

                let newClickCount = 1;

                if (userData.GPT && userData.GPT[today]) {
                    newClickCount = userData.GPT[today] + 1;
                }

                await updateDoc(userRef, {
                    [`GPT.${today}`]: newClickCount,
                });

                setClickCount(newClickCount);
                if (newClickCount >= 5) {
                    setCanClick(false);
                }
            } else {
                console.log('해당 uid를 가진 사용자가 없습니다. 새 문서를 만듭니다.');
                const userRef = doc(collection(db, 'users'));
                const today = new Date().toISOString().slice(0, 10);

                await setDoc(userRef, {
                    uid: user.uid,
                    GPT: {
                        [today]: 1,
                    },
                });

                setClickCount(1);
            }
        } catch (error) {
            console.error('Error updating click count:', error);
        }
    };

    // Firestore에서 기존 여행 설명 불러오기
    const fetchExistingDescription = async () => {
        setLoading(true);
        try {
            const docRef = doc(db, 'tourResults', docId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists() && docSnap.data().tripDescriptions) {
                setTripDescription(docSnap.data().tripDescriptions);
                setDescriptionExists(true);
            } else {
                setDescriptionExists(false);
            }
        } catch (error) {
            console.error('Error fetching trip description from Firestore:', error);
        } finally {
            setLoading(false);
        }
    };

    // 여행 설명을 Firestore에 저장
    const saveTripDescriptionToFirestore = async (description) => {
        try {
            const docRef = doc(db, 'tourResults', docId);
            await setDoc(docRef, {tripDescriptions: description}, {merge: true});
        } catch (error) {
            console.error('Error saving trip description to Firestore:', error);
        }
    };

    // ChatGPT API 호출
    const fetchTripDescription = async () => {
        if (!canClick || !docId || !user) return;

        setLoading(true);

        try {
            // API Gateway를 통한 Lambda 호출
            const response = await axios.post(
                'https://uhoylky3mg.execute-api.ap-northeast-2.amazonaws.com/ChatGPT/generate-description', // API Gateway 엔드포인트
                {
                    selectedRegion,
                    startDate,
                    endDate,
                    selectedActivity,
                    numAdults,
                    numChildren,
                    hotelName,
                    toursightData,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_GPT_API_KEY, // API Gateway의 x-api-key 헤더
                    },
                }
            );

            // Lambda 함수에서 반환된 여행 설명 저장
            const completion = response.data.description;
            const formattedDescription = completion
                .replace(/####/g, '<h4>')
                .replace(/###/g, '<h3>')
                .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
                .replace(/\n/g, '<br>');

            setTripDescription(formattedDescription);
            setDescriptionExists(true);
            await saveTripDescriptionToFirestore(formattedDescription);
            await updateClickCount();
        } catch (error) {
            console.error('Error fetching data from API Gateway:', error);
            setTripDescription('여행 설명을 가져오는 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        } finally {
            setLoading(false);
        }
    };


    const handleButtonClick = () => {
        if (!user) {
            alert('로그인이 필요합니다.');
            return;
        }
        setButtonClicked(true);
        fetchTripDescription();
    };

    // 컴포넌트가 처음 렌더링될 때 Firestore에서 기존 데이터 및 클릭 제한 확인
    useEffect(() => {
        if (docId) {
            fetchExistingDescription();
        }
    }, [docId]);

    // 로그인 상태가 변경될 때 클릭 제한 확인
    useEffect(() => {
        if (user) {
            checkClickLimit();
        } else {
            setCanClick(false); // 로그인하지 않은 경우 클릭 불가
        }
    }, [user]);

    return (
        <>
            <div style={titleContainerStyle}>
                <h2 style={titleStyle}>GPT가 알려주는 여행</h2>
            </div>
            <div style={containerStyle}>
                {descriptionExists ? (
                    loading ? (
                        <p style={contentStyle}>여행 설명을 불러오는 중입니다...</p>
                    ) : (
                        <div style={contentStyle} dangerouslySetInnerHTML={{__html: tripDescription}}></div>
                    )
                ) : docId ? (
                    <>
                        <button
                            style={buttonStyle}
                            onClick={handleButtonClick}
                            disabled={!canClick || loading || !user || authLoading}
                        >
                            {loading
                                ? '로딩 중...'
                                : !user || authLoading
                                    ? '로그인이 필요합니다'
                                    : canClick
                                        ? '여행 설명 생성하기'
                                        : '오늘의 생성 제한을 초과했습니다'}
                        </button>
                        {/* 클릭 횟수 상태 표시 (로그인한 경우에만 표시) */}
                        {user && <p>오늘의 생성 횟수: {clickCount}/5</p>}
                    </>
                ) : (
                    <p style={contentStyle}>여행계획 저장 후 LLM 생성이 가능합니다</p>
                )}
            </div>
        </>
    );
};

export default LLMDetailedInfo;

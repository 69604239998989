import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { collection, query, where, getDocs, updateDoc } from 'firebase/firestore'; // Firestore imports
import { db } from '../../firebase'; // Firebase setup

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

const ModalContent = styled.div`
    background-color: white;
    padding: 2.5rem;
    border-radius: 12px;
    width: 90%;
    max-width: 450px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

const Title = styled.h2`
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #333;
`;

const InputGroup = styled.div`
    margin-bottom: 1.2rem;
`;

const Label = styled.label`
    display: block;
    font-size: 1rem;
    margin-bottom: 0.4rem;
    color: #555;
`;

const Input = styled.input`
    width: calc(100% - 2rem);
    padding: 0.8rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 1rem;
    transition: border-color 0.2s;
    
    &:focus {
        border-color: #007bff;
        outline: none;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
`;

const Button = styled.button`
    padding: 0.8rem 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    background-color: ${(props) => (props.$cancel ? '#e74c3c' : '#007bff')}; /* Red for cancel, blue for save */
    color: white;
    width: 48%;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${(props) => (props.$cancel ? '#c0392b' : '#0056b3')};
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

const ErrorMessage = styled.p`
    color: red;
    font-size: 0.9rem;
    text-align: center;
`;

const InfoMessage = styled.p`
    color: #555;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    text-align: center;
`;

const NameEditModal = ({ isOpen, onClose, onSave, currentValue, userId }) => {
    const [name, setName] = useState(currentValue);
    const [error, setError] = useState('');
    const [isCheckingDuplicate, setIsCheckingDuplicate] = useState(false);
    const [isNameValid, setIsNameValid] = useState(false);
    const [canChangeName, setCanChangeName] = useState(false);

    useEffect(() => {
        setName(currentValue); // Reset value when modal is reopened
        checkCanChangeName(); // Check name change eligibility when modal opens
    }, [currentValue]);

    const validateName = (input) => {
        const nameRegex = /^[A-Za-z가-힣0-9]{1,10}$/;
        return nameRegex.test(input);
    };

    const checkDuplicateName = async (newName) => {
        setIsCheckingDuplicate(true);
        try {
            const q = query(collection(db, 'users'), where('name', '==', newName));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                setError('이미 사용 중인 이름입니다.');
                setIsNameValid(false);
            } else {
                setError('');
                setIsNameValid(true);
            }
        } catch (error) {
            console.error('Error checking for duplicate name:', error);
            setError('이름 중복 확인 중 오류가 발생했습니다.');
        }
        setIsCheckingDuplicate(false);
    };

    const checkCanChangeName = async () => {
        try {
            const q = query(collection(db, 'users'), where('uid', '==', userId));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                const data = userDoc.data();
                const lastChanged = data.lastNameChange;

                if (lastChanged) {
                    const lastChangedDate = lastChanged.toDate();
                    const currentDate = new Date();
                    const thirtyDays = 30 * 24 * 60 * 60 * 1000;

                    if (currentDate - lastChangedDate < thirtyDays) {
                        setError('이름은 한 달에 한 번만 변경할 수 있습니다.');
                        setCanChangeName(false);
                    } else {
                        setError('');
                        setCanChangeName(true);
                    }
                } else {
                    setCanChangeName(true);
                }
            } else {
                setError('사용자를 찾을 수 없습니다.');
            }
        } catch (error) {
            console.error('Error checking last name change date:', error);
            setError('이름 변경 제한을 확인하는 중 오류가 발생했습니다.');
        }
    };

    const handleSave = async () => {
        if (!validateName(name)) {
            setError('이름은 영어 대소문자, 한글, 숫자로 10자 이내로 입력해야 합니다.');
            return;
        }

        await checkDuplicateName(name);

        if (isNameValid && canChangeName) {
            try {
                const q = query(collection(db, 'users'), where('uid', '==', userId));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const userDoc = querySnapshot.docs[0];
                    const userDocRef = userDoc.ref;

                    await updateDoc(userDocRef, {
                        name,
                        lastNameChange: new Date(),
                    });

                    onSave(name);
                    onClose();
                } else {
                    setError('사용자를 찾을 수 없습니다.');
                }
            } catch (error) {
                console.error('Error updating name:', error);
                setError('이름 업데이트 중 오류가 발생했습니다.');
            }
        }
    };

    if (!isOpen) return null;

    return (
        <ModalOverlay>
            <ModalContent>
                <Title>이름 수정</Title>
                <InputGroup>
                    <Label>이름</Label>
                    <Input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onBlur={() => checkDuplicateName(name)}
                        disabled={!canChangeName}
                    />
                </InputGroup>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <ButtonWrapper>
                    <Button onClick={handleSave} disabled={!canChangeName}>저장</Button>
                    <Button $cancel onClick={onClose}>취소</Button>
                </ButtonWrapper>
            </ModalContent>
        </ModalOverlay>
    );
};

export default NameEditModal;

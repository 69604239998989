import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {doc, getDoc, collectionGroup, query, where, getDocs} from 'firebase/firestore';
import {db} from '../../firebase';  // Import Firestore configuration

const extractKoreanOrEnglish = (text) => {
    const korean = text ? text.match(/[가-힣\s]+/g)?.join(' ').trim() : '';
    const english = text ? text.match(/[a-zA-Z\s]+/g)?.join(' ').trim() : '';
    return korean || english || text; // 한글이 있으면 한글, 없으면 영어, 둘 다 없으면 원래 텍스트 반환
};

const DecisionDetails = ({
                             userName,
                             selectedActivity,
                             selectedRegion,
                             selectedRegionId,
                             hotelId,
                             startDate,
                             endDate,
                             numAdults,
                             numChildren
                         }) => {
    const [regionLogo, setRegionLogo] = useState('https://via.placeholder.com/400x300');  // Default placeholder
    const [hotelImageURL, setHotelImageURL] = useState('https://via.placeholder.com/400x300');
    const [activityLogo, setActivityLogo] = useState('https://via.placeholder.com/400x300');
    const [hotelName, setHotelName] = useState('');
    const [hotelURL, setHotelURL] = useState('');

    // Fetch the region data from Firestore based on the selectedRegionId
    const fetchRegionLogo = async (regionId) => {
        if (regionId) {
            const regionDocRef = doc(db, 'regions', regionId);
            const regionDoc = await getDoc(regionDocRef);

            if (regionDoc.exists()) {
                const regionData = regionDoc.data();
                setRegionLogo(regionData.logo || 'https://via.placeholder.com/400x300');  // Use the logo from Firestore or fallback to placeholder
            } else {
                console.log('No such region document!');
            }
        }
    };

    const fetchActivityLogo = async () => {
        if (selectedActivity) {
            try {
                // Firestore query using collectionGroup to search all 'activities' subcollections
                const activitiesQuery = query(
                    collectionGroup(db, "activities"), // Search across all 'activities' subcollections
                    where("ko", "==", selectedActivity) // Find activities where 'ko' matches selectedActivity
                );

                const activitiesSnapshot = await getDocs(activitiesQuery);

                if (!activitiesSnapshot.empty) {
                    activitiesSnapshot.forEach((doc) => {
                        const activityData = doc.data();
                        const activityImage = activityData.image || 'https://via.placeholder.com/400x300';
                        setActivityLogo(activityImage); // Set the activity image
                    });
                } else {
                    console.log('No activity found matching the selectedActivity');
                }
            } catch (error) {
                console.error('Error fetching activity logo:', error);
            }
        }
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');  // 월은 0부터 시작하므로 +1
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;  // YYYY-MM-DD 형식으로 반환
    };

    // Fetch the hotel details based on hotelId
    const fetchHotelDetails = async () => {
        if (hotelId) {
            try {
                const formattedStartDate = startDate ? formatDate(startDate) : '';
                const formattedEndDate = endDate ? formatDate(endDate) : '';

                if (!formattedStartDate || !formattedEndDate) {
                    throw new Error('Invalid date values: startDate or endDate is missing');
                }

                const requestBody = {
                    criteria: {
                        additional: {
                            currency: "KRW",
                            discountOnly: false,
                            language: "ko-kr",
                            occupancy: {
                                numberOfAdult: numAdults,
                                numberOfChildren: numChildren
                            }
                        },
                        checkInDate: formattedStartDate,
                        checkOutDate: formattedEndDate,
                        hotelId: [hotelId]
                    }
                };

                const headers = {
                    'Authorization': `${process.env.REACT_APP_AGODA_SITE_ID}:${process.env.REACT_APP_AGODA_API_KEY}`,
                    'Content-Type': 'application/json'
                };

                const response = await axios.post('/api/affiliateservice/lt_v1', requestBody, {headers});

                if (response.status === 200 && response.data && Array.isArray(response.data.results)) {
                    const hotelDetails = response.data.results[0];
                    setHotelName(extractKoreanOrEnglish(hotelDetails.hotelName));
                    setHotelImageURL(hotelDetails.imageURL || 'https://via.placeholder.com/400x300');
                    setHotelURL(hotelDetails.landingURL);
                }
            } catch (error) {
                console.error('Failed to fetch hotel details:', error.response ? error.response.data : error);
            }
        }
    };

    useEffect(() => {
        fetchRegionLogo(selectedRegionId);
    }, [selectedRegionId]);

    useEffect(() => {
        if (hotelId) {
            fetchHotelDetails();
        }
    }, [hotelId, startDate, endDate]);

    useEffect(() => {
        fetchActivityLogo();
    }, [selectedActivity]);

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        margin: '0.5rem 0.5rem',
    };

    const infoRowStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '1rem',
        gap: '1rem',
        maxWidth: '1200px',
        width: '100%',
    };

    const infoBoxStyle = {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    };

    const imageStyle = {
        width: '100%',
        height: '100%',
        maxHeight: '250px',
        borderRadius: '10px',
        objectFit: 'cover',
    };

    const titleStyle = {
        fontWeight: 'bold',
        fontSize: '1.2rem',
        marginBottom: '0rem',
        width: '100%',
        whiteSpace: 'nowrap', // 텍스트를 한 줄로 표시
        overflow: 'hidden', // 텍스트가 잘릴 때 숨김
        textOverflow: 'ellipsis', // 잘린 텍스트에 "..." 표시
    };

    const infoBoxWrapperStyle = {
        width: '33.33%',
        padding: '0',
    };

    return (
        <div style={containerStyle}>
            <div style={infoRowStyle}>
                <div style={infoBoxWrapperStyle}>
                    <div style={infoBoxStyle}>
                        <img src={regionLogo} alt="Region" style={imageStyle}/>
                        <p style={titleStyle}>{selectedRegion}</p>
                    </div>
                </div>
                <div style={infoBoxWrapperStyle}>
                    <div style={infoBoxStyle}>
                        <img src={activityLogo} alt="Activity" style={imageStyle}/>
                        <p style={titleStyle}>{selectedActivity}</p>
                    </div>
                </div>
                {hotelId && (
                    <div style={infoBoxWrapperStyle}>
                        <div style={infoBoxStyle}>
                            {/* 이미지 클릭 시 hotelURL로 이동 */}
                            <img
                                src={hotelImageURL}
                                alt="Hotel"
                                style={{...imageStyle, cursor: 'pointer'}}
                                onClick={() => window.open(hotelURL, '_blank')}
                                role="button"  // 접근성 향상을 위해 role 추가
                            />
                            {/* 이름 클릭 시 hotelURL로 이동 */}
                            <p
                                style={{...titleStyle, cursor: 'pointer'}}
                                onClick={() => window.open(hotelURL, '_blank')}
                                role="button"  // 접근성 향상을 위해 role 추가
                            >
                                {hotelName}
                            </p>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default DecisionDetails;

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

// Modal 스타일 정의
const StyledModal = styled(Modal)`
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 600px;
  max-width: 90%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CloseButton = styled.button`
  background: #3498db;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  align-self: flex-end; /* 버튼을 모달의 오른쪽 하단으로 배치 */
  margin-top: 20px;

  &:hover {
    background: #2980b9;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
`;

const Content = styled.div`
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
  overflow-y: auto; /* 수직 스크롤 적용 */
  padding-right: 10px; /* 스크롤바가 생겨도 글이 가려지지 않도록 패딩 적용 */
  flex-grow: 1; /* 콘텐츠 영역이 모달 안에서 최대 크기를 차지하도록 설정 */
`;

// JSON 데이터를 기반으로 약관을 표시하는 함수
const renderTerms = (terms) => {
  return Object.keys(terms).map((chapterKey, idx) => (
    <div key={idx}>
      <h2>{`제 ${chapterKey.replace('장', '')}장: ${terms[chapterKey]["제목"]}`}</h2>
      {Object.keys(terms[chapterKey]["조항"]).map((article, index) => (
        <p key={index}>
          <strong>{article}</strong>
          {/* 줄 바꿈 처리를 위해 dangerouslySetInnerHTML 사용 */}
          <span
            dangerouslySetInnerHTML={{
              __html: terms[chapterKey]["조항"][article].replace(/\n/g, "<br />")
            }}
          />
        </p>
      ))}
    </div>
  ));
};

// TermsModal 컴포넌트 정의
const TermsModal = ({ isOpen, onRequestClose }) => {
  const [terms, setTerms] = useState(null);

  useEffect(() => {
    // public 폴더의 JSON 파일을 가져옴
    const fetchTerms = async () => {
      try {
        const response = await fetch("/assets/terms.json");
        if (!response.ok) {
          throw new Error("Failed to fetch terms.json");
        }
        const data = await response.json();
        setTerms(data);
      } catch (error) {
        console.error("JSON 파일을 불러오는 중 오류가 발생했습니다.", error);
      }
    };

    if (isOpen) {
      fetchTerms(); // 모달이 열릴 때만 약관 불러오기
    }
  }, [isOpen]);

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Terms and Conditions"
      ariaHideApp={false}
    >
      <Title>이용약관</Title>
      <Content>
        {terms ? renderTerms(terms) : "Loading..."}
      </Content>
      <CloseButton onClick={onRequestClose}>닫기</CloseButton>
    </StyledModal>
  );
};

export default TermsModal;

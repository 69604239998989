import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Header from '../components/common/Header';
import Banner from '../components/home/Banner';
import PopularTours from '../components/home/PopularTours';
import Review from '../components/home/Review';
import Announcement from '../components/home/Announcement';
import Footer from '../components/common/Footer';
import Modal from '../components/common/Modal'; // Modal 컴포넌트 임포트

// MUI 아이콘 import
import RateReviewIcon from '@mui/icons-material/RateReview';

const Container = styled.div`
  max-width: 1200px; 
  margin: 0 auto; 
  padding: 0 0.5rem; 
  overflow: hidden;

  @media (max-width: 1200px) {
    padding: 0; 
  }
`;

const Section = styled.div`
  margin-bottom: 0; 
    
  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.h2`
  margin-left: 1rem;
  color: black;
  text-align: left;
  margin-bottom: 0.5rem;
  display: flex; 
  align-items: center; 
  cursor: pointer; 
`;

const IconWrapper = styled.div`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Home = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  // 페이지가 처음 로드될 때 localStorage 확인
  useEffect(() => {
    const neverShowModal = localStorage.getItem('neverShowModal');
    if (!neverShowModal) {
      setShowModal(true);
    }
  }, []);

  // 모달 닫기 핸들러
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // 다시 보지 않기 핸들러
  const handleNeverShowModal = () => {
    localStorage.setItem('neverShowModal', 'true');
    setShowModal(false);
  };

  return (
    <>
      <Header isHome={true} />
      <Container>
        {/* Modal 컴포넌트 사용 */}
        <Modal isOpen={showModal} onClose={handleCloseModal} onNeverShow={handleNeverShowModal} />
        <Section>
          <Banner />
        </Section>
        <Section>
          <PopularTours />
        </Section>
        <Section>
          <Title onClick={() => navigate('/review')}>
            <IconWrapper>
              <RateReviewIcon style={{ fontSize: 30, color: '#0096ff' }} />
            </IconWrapper>
            베스트 리뷰
          </Title>
          <Review />
        </Section>
        <Section>
          <Announcement />
        </Section>
        <Section>
          <Footer />
        </Section>
      </Container>
    </>
  );
};

export default Home;

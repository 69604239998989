import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase'; // Firestore setup

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

const ModalContent = styled.div`
    background-color: white;
    padding: 2.5rem;
    border-radius: 12px;
    width: 90%;
    max-width: 450px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
`;

const Title = styled.h3`
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: #333;
`;

const InputGroup = styled.div`
    margin-bottom: 1.5rem;
`;

const Label = styled.label`
    display: block;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #555;
`;

const Input = styled.input`
    width: calc(100% - 2rem);
    padding: 0.8rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 1rem;
    transition: border-color 0.2s;
    
    &:focus {
        border-color: #007bff;
        outline: none;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
`;

const Button = styled.button`
    padding: 0.8rem 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    background-color: ${(props) => (props.$cancel ? '#e74c3c' : '#007bff')}; /* Red for cancel, blue for save */
    color: white;
    width: 48%;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${(props) => (props.$cancel ? '#c0392b' : '#0056b3')};
    }
`;

const ErrorMessage = styled.p`
    color: red;
    font-size: 0.9rem;
    text-align: center;
`;

const BirthdayEditModal = ({ isOpen, onClose, onSave, currentValue, userId }) => {
    const [birthday, setBirthday] = useState(currentValue);

    useEffect(() => {
        setBirthday(currentValue); // Reset value when modal is reopened
    }, [currentValue]);

    const handleSave = async () => {
        try {
            // Query the Firestore database to find the document with the correct uid
            const q = query(collection(db, 'users'), where('uid', '==', userId));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0]; // Get the first matched document
                const userDocRef = userDoc.ref; // Get the reference to the document

                // Now update the birthday field in the document
                await updateDoc(userDocRef, {
                    birthday, // Update the birthday field
                });

                onSave(birthday);
                onClose();
            } else {
                console.error('No user document found to update.');
            }
        } catch (error) {
            console.error('Error updating birthday:', error);
        }
    };

    if (!isOpen) return null;

    return (
        <ModalOverlay>
            <ModalContent>
                <Title>생일 수정</Title>
                <InputGroup>
                    <Label>생일을 선택하세요:</Label>
                    <Input
                        type="date"
                        value={birthday}
                        onChange={(e) => setBirthday(e.target.value)}
                    />
                </InputGroup>
                <ButtonWrapper>
                    <Button onClick={handleSave}>저장</Button>
                    <Button $cancel onClick={onClose}>취소</Button>
                </ButtonWrapper>
            </ModalContent>
        </ModalOverlay>
    );
};

export default BirthdayEditModal;

import React, { useState } from 'react';
import styled from 'styled-components';
import TermsModal from '../auth/TermsModal'; // 모달 컴포넌트 경로를 정확히 설정하세요

const FooterWrapper = styled.footer`
  padding: 1rem;
  background-color: white;
  color: #61788A;
  text-align: center;
  border-top: 1px solid #ccc;
  position: relative;
`;

const Link = styled.p`
  color: #3498db;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  // 모달 상태 관리
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <FooterWrapper>
      <Link onClick={() => setIsModalOpen(true)}>이용약관</Link>
      <p>연락문의: <a href="mailto:msgaok97@gmail.com">msgaok97@gmail.com</a></p>

      {/* 모달 컴포넌트 삽입 */}
      <TermsModal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} />
    </FooterWrapper>
  );
};

export default Footer;

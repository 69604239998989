import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Autoplay} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styled from 'styled-components';
import {getStorage, ref, getDownloadURL} from 'firebase/storage';
import {useNavigate} from 'react-router-dom';

const BannerWrapper = styled.div`
    width: 100%;
    aspect-ratio: 16/9;
    max-height: 400px;

    .swiper {
        z-index: 0;
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        display: flex;
        max-height: 400px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
`;

const Slide = styled.div`
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.$backgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${(props) => props.$backgroundColor};  /* 배경색 설정 */
    cursor: pointer;
`;

const Banner = () => {
    const [images, setImages] = useState(JSON.parse(localStorage.getItem('bannerImages')) || []);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchImages = async () => {
            if (!images.length) {
                const storageRef = getStorage();
                const paths = ['Home/Banner/1.jpg', 'Home/Banner/2.jpg', 'Home/Banner/3.jpg'];
                const urls = await Promise.all(
                    paths.map(async (path) => {
                        const imageRef = ref(storageRef, path);
                        return await getDownloadURL(imageRef);
                    })
                );
                setImages(urls);
                localStorage.setItem('bannerImages', JSON.stringify(urls));
            }
        };

        fetchImages();
    }, [images]);

    const handleSlideClick = (index) => {
        switch (index) {
            case 0:
                navigate('/random-tour');
                break;
            case 1:
                alert('이벤트 준비중');
                break;
            case 2:
                navigate('/review');
                break;
            default:
                break;
        }
    };

    return (
        <BannerWrapper>
            <Swiper
                modules={[Pagination, Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                pagination={{clickable: true}}
                autoplay={{delay: 3000}}
            >
                {images.map((url, index) => (
                    <SwiperSlide key={index}>
                        <Slide
                            $backgroundImage={url}
                            $backgroundColor={
                                index === 0 || index === 2 ? '#F2F2F2' : '#0096FF'
                            }
                            onClick={() => handleSlideClick(index)}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </BannerWrapper>
    );
};

export default Banner;

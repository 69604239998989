import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../common/Header';
import { db } from '../../firebase';
import {
  Timestamp,
  doc,
  collection,
  collectionGroup,
  query,
  where,
  getDocs,
  getDoc,
  updateDoc,
  addDoc,
} from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import DecisionDetails from '../../components/summary/DecisionDetails';
import MatchingToursights from '../../components/summary/MatchingToursights';
import Reviews from '../../components/summary/Reviews';
import LLMDetailedInfo from '../../components/summary/LLMDetailedInfo';
import KakaoSDKLoader from '../../components/common/KakaoSDKLoader';
import styles from './Summary.module.css';
import axios from 'axios';

const Summary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState(location.state || {});
  const [isSaved, setIsSaved] = useState(false);
  const { user } = useAuth();
  const [userName, setUserName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  // New state variables for toursight data
  const [toursightData, setToursightData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // If location.state is not available, fetch data using docId from URL
    if (!location.state) {
      const searchParams = new URLSearchParams(location.search);
      const docIdFromUrl = searchParams.get('docId');

      if (docIdFromUrl) {
        const fetchData = async () => {
          try {
            const docRef = doc(db, 'tourResults', docIdFromUrl);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
              const data = docSnap.data();
              setState({
                selectedActivity: data.selectedActivity,
                selectedRegion: data.selectedRegion,
                selectedRegionId: data.selectedRegionId,
                hotelName: data.hotelName,
                hotelId: data.hotelId,
                startDate: data.startDate.toDate(),
                endDate: data.endDate.toDate(),
                docId: data.docId,
                numAdults: data.numAdults,
                numChildren: data.numChildren,
                fromProfile: true,
              });
            } else {
              console.log('No such document!');
              alert('존재하지 않는 여행 계획입니다.');
              navigate('/');
            }
          } catch (error) {
            console.error('Error fetching document: ', error);
            alert('데이터를 가져오는 중 오류가 발생했습니다.');
          }
        };
        fetchData();
      } else {
        // If no docId, redirect to home
        navigate('/');
      }
    }
  }, [location.state, location.search, navigate]);

  useEffect(() => {
    const fetchUserName = async () => {
      if (user) {
        const q = query(collection(db, 'users'), where('uid', '==', user.uid));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          setUserName(userData.name);
        }
      }
    };
    fetchUserName();
  }, [user]);

  const {
    selectedTheme,
    selectedActivity,
    selectedRegion,
    selectedRegionId,
    hotelName = '',
    hotelId = '',
    startDate,
    endDate,
    docId,
    fromProfile,
    numAdults,
    numChildren,
  } = state;

  // Function to fetch toursight data
  const fetchContentTypeIds = useCallback(async () => {
    setLoading(true);
    try {
      const regionDocRef = doc(db, 'regions', selectedRegionId);
      const regionDocSnapshot = await getDoc(regionDocRef);

      if (!regionDocSnapshot.exists()) {
        setLoading(false);
        return;
      }

      const regionToursightsRefs = regionDocSnapshot.data().Toursights || [];

      const activitiesQuery = query(
        collectionGroup(db, 'activities'),
        where('ko', '==', selectedActivity)
      );
      const activitySnapshot = await getDocs(activitiesQuery);

      if (activitySnapshot.empty) {
        setLoading(false);
        return;
      }

      const activityDoc = activitySnapshot.docs[0];
      const activityToursightsRefs = activityDoc.data().Toursights || [];

      const matchingToursightsRefs = regionToursightsRefs.filter((regionRef) =>
        activityToursightsRefs.some((activityRef) => regionRef.id === activityRef.id)
      );

      if (matchingToursightsRefs.length === 0) {
        setLoading(false);
        return;
      }

      const toursightsQuery = query(
        collection(db, 'Toursights'),
        where('__name__', 'in', matchingToursightsRefs.map((ref) => ref.id))
      );

      const matchingToursightsSnapshot = await getDocs(toursightsQuery);

      const toursightDataArray = matchingToursightsSnapshot.docs.map((doc) => ({
        id: doc.id,
        contenttypeid: doc.data().contenttypeid || '12', // Default contenttypeid
      }));

      const serviceKey = process.env.REACT_APP_SERVICE_KEY;

      const apiDataPromises = toursightDataArray.map(async ({ id, contenttypeid }) => {
        const apiUrl = `https://apis.data.go.kr/B551011/KorService1/detailCommon1?serviceKey=${serviceKey}&MobileOS=ETC&MobileApp=AppTest&_type=json&contentId=${id}&contentTypeId=${contenttypeid}&defaultYN=Y&firstImageYN=Y&areacodeYN=Y&catcodeYN=Y&addrinfoYN=Y&mapinfoYN=Y&overviewYN=Y&numOfRows=10&pageNo=1`;

        try {
          const response = await axios.get(apiUrl);
          const item = response.data.response.body.items.item[0];
          return {
            id,
            title: item.title,
            addr1: item.addr1,
            firstimage: item.firstimage || 'https://firebasestorage.googleapis.com/v0/b/randomtour-8421b.appspot.com/o/assets%2Ffontlogo_narrow.png?alt=media&token=a5ab8567-26ed-4a2b-aefa-78ab8bb1c00c',
            overview: item.overview,
            contenttypeid,
          };
        } catch (error) {
          console.error(`Failed to fetch data for ID ${id} and contentTypeId ${contenttypeid}:`, error);
          return {
            id,
            title: 'N/A',
            addr1: 'N/A',
            firstimage: '',
            overview: 'N/A',
            contenttypeid,
          };
        }
      });

      const apiData = await Promise.all(apiDataPromises);
      setToursightData(apiData);
    } catch (error) {
      console.error('Error fetching contentTypeIds:', error);
    } finally {
      setLoading(false);
    }
  }, [selectedRegionId, selectedActivity]);

  useEffect(() => {
    if (selectedRegionId && selectedActivity) {
      fetchContentTypeIds();
    }
  }, [selectedRegionId, selectedActivity, fetchContentTypeIds]);

  const handleShareKakao = () => {
    if (window.Kakao && window.Kakao.Link) {
      const shareUrl = `${window.location.origin}${window.location.pathname}?docId=${docId}`;
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: '여행 계획 결과',
          description: `랜덤으로 만든 여행 계획입니다!\n여행지: ${selectedRegion}, 활동: ${selectedActivity}, 숙소: ${hotelName}`,
          imageUrl: 'https://firebasestorage.googleapis.com/v0/b/randomtour-8421b.appspot.com/o/logo%2Flogo_Icon.png?alt=media&token=14a1da7c-2c21-4911-ab1f-24fac1f82b59',
          link: {
            mobileWebUrl: shareUrl,
            webUrl: shareUrl,
          },
        },
        buttons: [
          {
            title: '결과 보기',
            link: {
              mobileWebUrl: shareUrl,
              webUrl: shareUrl,
            },
          },
        ],
      });
    } else {
      alert('Kakao SDK가 초기화되지 않았거나, Kakao.Link 객체가 준비되지 않았습니다.');
    }
  };

  const handleShareInstagram = () => {
    const shareUrl = `${window.location.origin}${window.location.pathname}?docId=${docId}`;
    const url = `https://www.instagram.com/?url=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank');
  };

  const handleShareTwitter = () => {
    const shareUrl = `${window.location.origin}${window.location.pathname}?docId=${docId}`;
    const text = `랜덤으로 만든 여행 계획입니다!\n여행지: ${selectedRegion}, 활동: ${selectedActivity}`;
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text
    )}&url=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank');
  };

  const handleShare = async () => {
    if (!docId) {
      // If docId doesn't exist, prompt to save the plan
      const confirmSave = window.confirm('공유하기 위해서는 여행 계획을 저장해야 합니다. 저장하시겠습니까?');
      if (confirmSave) {
        const newDocId = await handleSave(false); // Save without navigation
        if (newDocId) {
          // Update docId after saving and open modal
          setState((prevState) => ({ ...prevState, docId: newDocId }));
          setIsSaved(true);
          setIsModalOpen(true);
        } else {
          alert('저장에 실패하여 공유할 수 없습니다.');
        }
      } else {
        // Do not open modal if not saved
        return;
      }
    } else {
      // If docId exists, open modal directly
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = async (shouldNavigate = true) => {
    if (!user) {
      alert('저장을 위해서는 로그인이 필요합니다.');
      navigate('/login');
      return null;
    }

    try {
      let newDocId = docId;

      const formattedStartDate =
        startDate instanceof Timestamp ? startDate : Timestamp.fromDate(new Date(startDate));
      const formattedEndDate =
        endDate instanceof Timestamp ? endDate : Timestamp.fromDate(new Date(endDate));

      if (docId) {
        const tourResultRef = doc(db, 'tourResults', docId);
        const dataToUpdate = {
          selectedTheme,
          selectedActivity,
          selectedRegion,
          selectedRegionId,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          numAdults,
          numChildren,
        };

        // Add hotel info if available
        if (hotelName) {
          dataToUpdate.hotelName = hotelName;
        }
        if (hotelId) {
          dataToUpdate.hotelId = hotelId;
        }

        await updateDoc(tourResultRef, dataToUpdate);

        if (shouldNavigate) {
          alert('수정되었습니다.');
          setIsSaved(true);
          navigate('/summary?docId=' + docId);
        }
      } else {
        const dataToSave = {
          userID: user.uid,
          selectedTheme,
          selectedActivity,
          selectedRegion,
          selectedRegionId,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          numAdults,
          numChildren,
          timestamp: Timestamp.now(),
        };

        // Add hotel info if available
        if (hotelName) {
          dataToSave.hotelName = hotelName;
        }
        if (hotelId) {
          dataToSave.hotelId = hotelId;
        }

        const newDocRef = await addDoc(collection(db, 'tourResults'), dataToSave);

        newDocId = newDocRef.id;

        await updateDoc(newDocRef, {
          docId: newDocId,
        });

        if (shouldNavigate) {
          alert('새로운 여행 계획이 저장되었습니다.');
          setIsSaved(true);
          navigate('/summary?docId=' + newDocId);
        }
      }

      // Clear local storage
      localStorage.removeItem('selectedActivityName');
      localStorage.removeItem('selectedRegion');
      localStorage.removeItem('selectedRegionId');
      localStorage.removeItem('docId');

      return newDocId;
    } catch (e) {
      console.error('Error saving document: ', e);
      alert('저장 중 오류가 발생했습니다.');
      return null;
    }
  };

  const handleHomeNavigate = () => {
    navigate('/');
  };

  // If data is not loaded, show loading indicator
  if (!selectedActivity || !selectedRegion) {
    return (
      <div className={styles.loadingContainer}>
        <p>여행 계획을 불러오는 중입니다...</p>
      </div>
    );
  }

  return (
    <>
      <Header pageTitle="여행 최종 안내" />
      <div className={styles.summaryContainer}>
        <DecisionDetails
          userName={userName}
          selectedActivity={selectedActivity}
          selectedRegion={selectedRegion}
          selectedRegionId={selectedRegionId}
          hotelId={hotelId}
          startDate={startDate}
          endDate={endDate}
          numAdults={numAdults}
          numChildren={numChildren}
        />
        <MatchingToursights
          toursightData={toursightData}
          loading={loading}
        />
        <Reviews selectedActivity={selectedActivity} selectedRegionId={selectedRegionId} />
        <LLMDetailedInfo
          userID={user ? user.uid : null}
          docId={docId}
          selectedActivity={selectedActivity}
          selectedRegion={selectedRegion}
          startDate={startDate}
          endDate={endDate}
          hotelName={hotelName}
          numAdults={numAdults}
          numChildren={numChildren}
          toursightData={toursightData}
          loadingToursightData={loading}
        />
        <KakaoSDKLoader />
        <div className={styles.buttonContainer}>
          <button className={styles.shareButton} onClick={handleShare}>
            공유하기
          </button>
          {isSaved || fromProfile ? (
            <button className={styles.saveButton} onClick={handleHomeNavigate}>
              홈으로 이동하기
            </button>
          ) : (
            <button className={styles.saveButton} onClick={handleSave}>
              저장하기
            </button>
          )}
        </div>
      </div>

      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              <h2>일행과 공유하기</h2>
              <button className={styles.closeModal} onClick={handleCloseModal}>
                X
              </button>
            </div>
            <div className={styles.modalBody}>
              <img src="assets/kakao-logo.png" alt="Kakao" onClick={handleShareKakao} />
              <img
                src="assets/instagram-logo.svg"
                alt="Instagram"
                onClick={handleShareInstagram}
              />
              <img src="assets/x.svg" alt="Twitter" onClick={handleShareTwitter} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Summary;

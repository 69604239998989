import React from 'react';
import styled from 'styled-components';
import Header from '../../components/common/Header';
import ReviewSection from '../../components/Review/ReviewSection';
import LatestReviews from '../../components/Review/LatestReviews';
import PopularByRegion from '../../components/Review/PopularByRegion';
import PopularByActivity from '../../components/Review/PopularByActivity';
import BestReviews from '../../components/home/Review';
import Footer from '../../components/common/Footer';

// MUI Icons import
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import CelebrationIcon from '@mui/icons-material/Celebration';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
`;

const Title = styled.h2`
  margin-left: 1rem;
  color: black;
  text-align: left;
  margin-bottom: 0.5rem;
  display: flex; /* 아이콘과 텍스트를 같은 줄에 배치 */
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: 0.5rem; /* 아이콘과 텍스트 간의 간격 조절 */
  display: flex;
  align-items: center;
`;

const ReviewPage = () => (
  <>
    <Header pageTitle="리뷰" />
    <Container>
      <ReviewSection />

      {/* 베스트 리뷰 섹션 */}
      <Title>
        <IconWrapper>
          <ThumbUpAltIcon style={{ fontSize: 30, color: '#0096ff' }} />
        </IconWrapper>
        베스트 리뷰
      </Title>
      <BestReviews />

      {/* 최신 리뷰 섹션 */}
      <Title>
        <IconWrapper>
          <FiberNewIcon style={{ fontSize: 30, color: '#ff6347' }} />
        </IconWrapper>
        최신 리뷰
      </Title>
      <LatestReviews />

      {/* 지역별 인기 리뷰 섹션 */}
      <Title>
        <IconWrapper>
          <TravelExploreIcon style={{ fontSize: 30, color: '#32cd32' }} />
        </IconWrapper>
        지역별 인기 리뷰
      </Title>
      <PopularByRegion />

      {/* 챌린지별 인기 리뷰 섹션 */}
      <Title>
        <IconWrapper>
          <CelebrationIcon style={{ fontSize: 30, color: '#ffb400' }} />
        </IconWrapper>
        챌린지별 인기 리뷰
      </Title>
      <PopularByActivity />
    </Container>
    <Footer />
  </>
);

export default ReviewPage;

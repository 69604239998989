import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore'; // Firestore 관련 함수 import

const UserStatus = () => {
    const [tourCount, setTourCount] = useState(0);

    // Firestore에서 문서 수를 가져오는 함수
    useEffect(() => {
        const fetchTourCount = async () => {
            const db = getFirestore(); // Firebase Firestore 초기화
            const tourResultsCollection = collection(db, '/tourResults'); // /tourResults 컬렉션 참조
            const tourResultsSnapshot = await getDocs(tourResultsCollection); // 컬렉션의 모든 문서를 가져옴
            setTourCount(tourResultsSnapshot.size); // 문서의 개수를 상태로 저장
        };

        fetchTourCount(); // 컴포넌트가 마운트될 때 Firestore에서 문서 수를 가져옴
    }, []);

    const containerStyle = {
        height: '4rem',
        padding: '1.5rem',
        backgroundColor: '#f1f1f1',
        textAlign: 'center'
    };

    const textStyle = {
        fontSize: '1rem',
        color: '#666'
    };

    return (
        <div style={containerStyle}>
            <p style={textStyle}>현재까지 {tourCount.toLocaleString()}개의 여행 계획이 만들어졌습니다.</p>
        </div>
    );
};

export default UserStatus;

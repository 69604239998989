import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs, updateDoc, arrayUnion, deleteDoc } from 'firebase/firestore';
import {deleteObject, ref, listAll, getStorage} from 'firebase/storage';
import { db } from '../../firebase';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { Card, CardContent, CardMedia, Typography, Box, CircularProgress, Container, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { Rating } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreVertIcon from '@mui/icons-material/MoreVert'; // MoreVertIcon 추가
import { useAuth } from '../../context/AuthContext'; // 로그인 상태 확인

const ReviewDetailPage = () => {
  const { reviewId } = useParams();
  const [review, setReview] = useState(null);
  const [authorName, setAuthorName] = useState('');
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { user } = useAuth(); // 로그인한 사용자 정보
  const [anchorEl, setAnchorEl] = useState(null); // 메뉴의 anchor 관리
  const open = Boolean(anchorEl); // 메뉴 열림 여부 확인
  const navigate = useNavigate();
  const storage = getStorage();

  useEffect(() => {
    const fetchReview = async () => {
      const reviewDoc = await getDoc(doc(db, 'reviews', reviewId));
      if (reviewDoc.exists()) {
        const reviewData = reviewDoc.data();
        setReview(reviewData);
        if (reviewData.userID) {
          fetchAuthorName(reviewData.userID);
        }
      } else {
        console.log('리뷰를 찾을 수 없습니다.');
      }
    };

    const fetchAuthorName = async (userID) => {
      const usersQuery = query(collection(db, 'users'), where('uid', '==', userID));
      const userSnapshot = await getDocs(usersQuery);
      if (!userSnapshot.empty) {
        const userData = userSnapshot.docs[0].data();
        setAuthorName(userData.name || '알 수 없음');
      } else {
        setAuthorName('알 수 없음');
      }
    };

    fetchReview();
  }, [reviewId]);

  if (!review) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', marginTop: 5 }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ marginTop: 2 }}>리뷰를 불러오는 중입니다...</Typography>
      </Container>
    );
  }

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsLightboxOpen(true);
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return date.toLocaleDateString();
  };

  const handleLikeClick = async () => {
    if (!user) {
      alert('로그인이 필요합니다.');
      return;
    }

    if (review.likedUsers && review.likedUsers.includes(user.uid)) {
      alert('이미 좋아요를 눌렀습니다.');
      return;
    }

    try {
      const reviewRef = doc(db, 'reviews', reviewId);
      await updateDoc(reviewRef, {
        likes: (review.likes || 0) + 1,
        likedUsers: arrayUnion(user.uid),
      });

      // 상태 업데이트
      setReview((prevReview) => ({
        ...prevReview,
        likes: (prevReview.likes || 0) + 1,
        likedUsers: [...(prevReview.likedUsers || []), user.uid],
      }));
    } catch (error) {
      console.error('좋아요 업데이트 실패:', error);
    }
  };

  // 메뉴 핸들러
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

   const handleEditClick = () => {
    navigate(`/edit-review/${reviewId}`, {
      state: { reviewId }, // 선택된 리뷰의 ID를 상태로 전달
    });
    handleMenuClose();
  };

  const handleDeleteClick = async () => {
  const confirmDelete = window.confirm('정말로 이 리뷰를 삭제하시겠습니까?');
  if (confirmDelete) {
    try {
      // Firebase Firestore에서 리뷰 문서 삭제 전에 Storage의 이미지 삭제
      const reviewRef = doc(db, 'reviews', reviewId);
      const reviewSnapshot = await getDoc(reviewRef);

      if (reviewSnapshot.exists()) {
        const reviewData = reviewSnapshot.data();

        // 이미지 경로가 존재하면 Firebase Storage에서 이미지 파일 삭제
        if (reviewData.images && reviewData.imagePaths.length > 0) {
          const tripID = reviewData.tripID
          const folderRef = ref(storage, `review_images/${tripID}`);
          await deleteFolderContents(folderRef); // 폴더 내 모든 이미지 삭제
        }
      }

      // Firestore에서 리뷰 문서 삭제
      await deleteDoc(reviewRef);
      alert('리뷰가 삭제되었습니다.');
      handleMenuClose();
      navigate('/review'); // 리뷰 목록 페이지로 이동
    } catch (error) {
      console.error('리뷰 삭제 중 오류 발생:', error);
      alert('리뷰 삭제 중 오류가 발생했습니다.');
    }
  }
};

// 폴더 내 모든 파일 삭제 함수
const deleteFolderContents = async (folderRef) => {
  try {
    // 폴더 내의 모든 파일과 하위 폴더 목록 가져오기
    const list = await listAll(folderRef);

    // 모든 파일 개별 삭제
    const deletePromises = list.items.map((fileRef) => deleteObject(fileRef));

    // 모든 파일이 삭제될 때까지 대기
    await Promise.all(deletePromises);
  } catch (error) {
    console.error('폴더 내 파일 삭제 중 오류 발생:', error);
  }
};


  return (
  <>
    <Header />
    <Container maxWidth="md" sx={{ marginTop: 4 }}>
      <Card>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" component="div" gutterBottom>
            {review.title}
          </Typography>

          {/* 작성자가 로그인한 사용자와 동일할 경우 MoreVertIcon을 표시 */}
          {review.userID === user?.uid && (
            <>
              <IconButton aria-label="more options" onClick={handleMenuClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={handleEditClick}>수정</MenuItem>
                <MenuItem onClick={handleDeleteClick}>삭제</MenuItem>
              </Menu>
            </>
          )}
        </CardContent>
        <CardContent>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            작성자: {authorName} | 작성일: {review.createdAt ? formatDate(review.createdAt) : '정보 없음'}
          </Typography>

          <Box sx={{ marginTop: 3, marginBottom: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  <strong>장소:</strong> {review.trip?.selectedRegion || '정보 없음'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>활동:</strong> {review.trip?.selectedActivity || '정보 없음'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>여행일자:</strong>
                  {review.trip?.startDate ? formatDate(review.trip.startDate) : '정보 없음'} ~
                  {review.trip?.endDate ? formatDate(review.trip.endDate) : '정보 없음'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  <strong>별점:</strong>
                </Typography>
                <Rating value={review.rating} readOnly precision={0.5} />
              </Grid>
            </Grid>
          </Box>

          <Typography variant="body1" paragraph>
            {review.content}
          </Typography>

          {/* 좋아요 및 좋아요 수 표시 */}
          <Box display="flex" alignItems="center" gap={1}>
            <IconButton aria-label="like" onClick={handleLikeClick} disabled={review.likedUsers && review.likedUsers.includes(user?.uid)}>
              <FavoriteIcon color={review.likedUsers && review.likedUsers.includes(user?.uid) ? "error" : "default"} />
            </IconButton>
            <Typography variant="body2">{review.likes || 0}</Typography>
          </Box>

          {review.images && review.images.length > 0 && (
            <>
              {/* 이미지가 2장 이상인 경우, 몇 장 있는지 알려주는 표시 */}
              {review.images.length > 1 && (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                  총 {review.images.length}장의 이미지가 있습니다.
                </Typography>
              )}

              <Box sx={{ display: 'flex', gap: 2, overflowX: 'auto', mt: 3 }}>
                {review.images.map((url, index) => (
                  <CardMedia
                    key={index}
                    component="img"
                    image={url}
                    alt={`Review Image ${index}`}
                    onClick={() => handleImageClick(index)}
                    sx={{ maxHeight: 240, maxWidth: 320, cursor: 'pointer', borderRadius: 2, objectFit: 'cover' }}
                  />
                ))}
              </Box>

              <Lightbox
                open={isLightboxOpen}
                close={() => setIsLightboxOpen(false)}
                slides={review.images.map((src) => ({ src }))}
                index={currentImageIndex}
                onIndexChange={setCurrentImageIndex}
              />
            </>
          )}
        </CardContent>
      </Card>
    </Container>
    <Footer />
  </>
);
};

export default ReviewDetailPage;

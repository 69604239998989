import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {auth, db} from '../../firebase';
import {updateProfile, linkWithCredential, PhoneAuthProvider} from 'firebase/auth';
import {doc, setDoc} from 'firebase/firestore';
import Header from '../common/Header';
import PhoneInput from './SignUp/PhoneInput';
import BirthdayInput from './SignUp/BirthdayInput';
import NameInput from './SignUp/NameInput';
import CircularProgress from '@mui/material/CircularProgress'; // CircularProgress 임포트
import './GoogleSignUp.css';

const GoogleSignUp = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [birthday, setBirthday] = useState('');
    const [phoneCheckMessage, setPhoneCheckMessage] = useState(''); // PhoneInput에서 인증 메시지 받기
    const [error, setError] = useState('');
    const [nameCheckMessage, setNameCheckMessage] = useState('');
    const [verificationId, setVerificationId] = useState(''); // 인증 ID 저장
    const [verificationCode, setVerificationCode] = useState(''); // 사용자가 입력한 인증 코드
    const [loading, setLoading] = useState(false); // 로딩 상태 관리
    const navigate = useNavigate();

    // 회원가입 처리 함수
    const handleSignUp = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true); // 로딩 시작

        // 1. 전화번호 인증이 완료되었는지 확인
        if (!verificationId || !verificationCode) {
            setError('휴대전화 번호 인증을 완료해주세요.');
            setLoading(false); // 로딩 종료
            return;
        }

        // 2. 이름 중복 확인 여부 체크
        if (!nameCheckMessage.includes('사용 가능한')) {
            setError('이름 중복 확인을 해주세요.');
            setLoading(false); // 로딩 종료
            return;
        }

        try {
            // 3. 현재 로그인된 Google 사용자 가져오기
            const user = auth.currentUser;

            if (user) {
                // 4. 인증 코드가 유효한지 확인하여 `phoneCredential` 생성
                const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode);

                // 5. 전화번호를 현재 Google 계정에 연결
                await linkWithCredential(user, phoneCredential);

                // 6. 사용자 프로필 업데이트
                await updateProfile(user, {displayName: name});

                const currentDate = new Date();
                await setDoc(doc(db, 'users', user.uid), {
                    uid: user.uid,
                    email: user.email,
                    name,
                    birthday,
                    phone,
                    profilePicture: 'https://firebasestorage.googleapis.com/v0/b/randomtour-8421b.appspot.com/o/profilePictures%2Fdefault-profile.png?alt=media&token=c7da2234-f459-47d2-9b88-8bd568c9b20f',
                    createdAt: currentDate,
                });

                // 회원가입 완료 후 페이지 이동
                navigate('/signup-complete');
            } else {
                setError('사용자 인증에 실패했습니다. 다시 시도해주세요.');
            }
        } catch (error) {
            if (error.code === 'auth/invalid-verification-code') {
                setError('잘못된 인증 코드입니다. 다시 시도해주세요.');
            } else if (error.code === 'auth/credential-already-in-use') {
                setError('이미 사용 중인 휴대전화 번호입니다.');
            } else {
                setError('회원가입 중 오류가 발생했습니다.');
                console.error('회원가입 중 오류가 발생했습니다:', error);
            }
        } finally {
            setLoading(false); // 로딩 종료
        }
    };

    return (
        <>
            <Header/>
            <div className="signup-pageWrapper">
                <div className="signup-formWrapper">
                    <h2 className="signup-title">추가 정보 입력</h2>
                    <p className="sub-title">추가 정보 입력 후 정상 사용 가능합니다</p>
                    <form onSubmit={handleSignUp}>
                        <NameInput
                            name={name}
                            setName={setName}
                            setNameCheckMessage={setNameCheckMessage}
                        />
                        <BirthdayInput onBirthdayChange={setBirthday}/>
                        <PhoneInput
                            onPhoneChange={setPhone} // 휴대전화 번호 변경 시 업데이트
                            phoneCheckMessage={phoneCheckMessage} // 휴대전화 인증 상태 메시지
                            setPhoneCheckMessage={setPhoneCheckMessage} // 인증 상태 메시지 설정
                            setVerificationCode={setVerificationCode} // 사용자 입력 인증 코드 저장
                            onSendVerification={setVerificationId} // 인증 ID 저장
                            context="GoogleSignUp"
                        />
                        {error && <p className="signup-error">{error}</p>}
                        <div className="signup-buttonWrapper">
                            {loading ? ( // 로딩 중일 때 CircularProgress를 표시
                                <CircularProgress/>
                            ) : (
                                <button type="submit" className="signup-button signup-buttonPrimary">
                                    인증번호 확인 및 회원가입 완료
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default GoogleSignUp;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardMedia, CardContent, Typography, Button, CircularProgress, Container } from '@mui/material';
import { Alert } from '@mui/material';
import Header from "../common/Header";  // Header는 수정하지 않음

const Detail = () => {
  const { contentid, contentTypeId } = useParams();  // URL 파라미터에서 contentid와 contentTypeId 가져오기
  const [touristDetail, setTouristDetail] = useState(null);  // 상세 정보를 저장할 상태
  const [loading, setLoading] = useState(true);  // 로딩 상태
  const [error, setError] = useState(null);  // 에러 상태

  const serviceKey = process.env.REACT_APP_SERVICE_KEY;  // 환경 변수에서 서비스 키 가져오기

  // 관광지 상세 정보를 가져오는 함수
  useEffect(() => {
    const fetchDetail = async () => {
      try {
        const response = await fetch(
          `https://apis.data.go.kr/B551011/KorService1/detailCommon1?serviceKey=${serviceKey}&MobileOS=ETC&MobileApp=AppTest&_type=json&contentId=${contentid}&contentTypeId=${contentTypeId}&defaultYN=Y&firstImageYN=Y&areacodeYN=Y&catcodeYN=Y&addrinfoYN=Y&mapinfoYN=Y&overviewYN=Y&numOfRows=10&pageNo=1`
        );
        const data = await response.json();

        // API 응답이 성공적인 경우 상태에 저장
        if (data.response.header.resultCode === "0000") {
          setTouristDetail(data.response.body.items.item[0]);
        } else {
          setError("API 요청에 실패했습니다.");
        }
      } catch (error) {
        setError("데이터를 불러오는 중 오류가 발생했습니다.");
      } finally {
        setLoading(false);  // 로딩 상태를 false로 설정
      }
    };

    fetchDetail();
  }, [contentid, contentTypeId, serviceKey]);

  if (loading) {
    return (
      <Container style={{ textAlign: 'center', marginTop: '50px' }}>
        <CircularProgress />
        <Typography variant="body1" style={{ marginTop: '20px' }}>
          로딩 중...
        </Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container style={{ textAlign: 'center', marginTop: '50px' }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <>
      <Header />  {/* Header는 변경하지 않음 */}
      <Container maxWidth="md" style={{ marginTop: '20px' }}>
        {touristDetail ? (
          <Card>
            {touristDetail.firstimage && (
              <CardMedia
                component="img"
                height="300"
                image={touristDetail.firstimage}
                alt={touristDetail.title}
                style={{ objectFit: 'cover' }}
              />
            )}
            <CardContent>
              <Typography variant="h4" gutterBottom>
                {touristDetail.title}
              </Typography>
              <Typography variant="body1" paragraph>
                {touristDetail.overview}
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                주소: {touristDetail.addr1}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                href={`https://www.google.com/maps/search/?api=1&query=${touristDetail.mapy},${touristDetail.mapx}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginTop: '10px' }}
              >
                구글 지도에서 보기
              </Button>
            </CardContent>
          </Card>
        ) : (
          <Typography variant="body1">관광지 정보를 불러오지 못했습니다.</Typography>
        )}
      </Container>
    </>
  );
};

export default Detail;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import RegionSelect from '../components/Review/RegionSelect';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Pagination from '@mui/material/Pagination';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

// 스타일 정의
const TouristSpotContainer = styled.div`
  padding: 1rem;
  background-color: white;
  border-radius: 10px;
  width: calc(100% - 2rem);
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
`;

const TouristSpotGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
`;

const LoadingText = styled.p`
  font-size: 1.2rem;
  color: grey;
  text-align: center;
`;

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

// 상단 고정 스타일 추가
const StickyRegionSelect = styled.div`
  position: ${(props) => (props.isSticky ? 'fixed' : 'relative')};
  top: 0; /* 상단 고정 높이 조정 */
  width: 100%;
  z-index: 500; /* 다른 요소 위에 표시 */
  background-color: white; /* 고정 시 배경 색 */
  transition: top 0.3s ease-in-out;
  box-shadow: ${(props) => (props.isSticky ? '0px 4px 8px rgba(0, 0, 0, 0.1)' : 'none')};
`;

const TitleSection = styled.div`
  text-align: center;
  padding: 0.5rem 0;
`;

const MainTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
`;

const SubTitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const ToursightPage = () => {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [touristSpots, setTouristSpots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isSticky, setIsSticky] = useState(false); // 상단 고정 상태

  const serviceKey = process.env.REACT_APP_SERVICE_KEY;

  const fetchTouristSpots = async (region, page) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://apis.data.go.kr/B551011/KorService1/areaBasedSyncList1?serviceKey=${serviceKey}&numOfRows=10&pageNo=${page}&MobileOS=ETC&MobileApp=AppTest&_type=json&listYN=Y&arrange=A&areaCode=32&sigunguCode=${region.toursiguncd}&contentTypeId=12`
      );
      const data = await response.json();
      setTouristSpots(data.response.body.items.item);
      setTotalPages(Math.ceil(data.response.body.totalCount / 10));
    } catch (error) {
      console.error('Failed to fetch tourist spots:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRegionSelect = (region) => {
    setSelectedRegion(region);
    setCurrentPage(1);
    fetchTouristSpots(region, 1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    if (selectedRegion) {
      fetchTouristSpots(selectedRegion, value);
    }
  };

  // 스크롤 이벤트 핸들러
  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = 60; // 고정할 높이 설정
      if (window.scrollY > headerHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="content">
      <Header />
      <TitleSection>
        <MainTitle>지역 관광지 소개</MainTitle>
        <SubTitle>강원 지역 관광지 소개</SubTitle>
      </TitleSection>

      {/* RegionSelect를 StickyRegionSelect로 변경 */}
      <StickyRegionSelect isSticky={isSticky}>
        <RegionSelect onSelectRegion={handleRegionSelect} />
      </StickyRegionSelect>

      {selectedRegion && (
        <div>
          {loading ? (
            <LoadingText>관광지 정보를 불러오는 중...</LoadingText>
          ) : (
            <TouristSpotContainer>
              <TouristSpotGrid>
                {touristSpots.map((spot) => (
                  <Card key={spot.contentid} sx={{ width: '100%' }}>
                    <CardActionArea component={Link} to={`/toursight/${spot.contentid}/12`}>
                      <CardMedia
                        component="img"
                        height="140"
                        image={spot.firstimage || 'https://firebasestorage.googleapis.com/v0/b/randomtour-8421b.appspot.com/o/assets%2Ffontlogo_narrow.png?alt=media&token=a5ab8567-26ed-4a2b-aefa-78ab8bb1c00c'}
                        alt={spot.title}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {spot.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {spot.addr1 || '주소 정보 없음'}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </TouristSpotGrid>
              <PaginationContainer>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                  siblingCount={1}
                  boundaryCount={1}
                />
              </PaginationContainer>
            </TouristSpotContainer>
          )}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default ToursightPage;

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';  // Firestore configuration
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import CardActionArea from '@mui/material/CardActionArea';

const ReviewsContainer = styled.div`
    background-color: white;
    overflow: hidden;
    max-width: 1200px;
    padding: 0;
`;

const Title = styled.h2`
    color: #0096ff;
    text-align: left;
    margin-bottom: 1rem;
    margin-left: 1rem;
`;

const StyledSwiper = styled(Swiper)`
    .swiper-wrapper {
        display: flex;
        align-items: center;
        margin-left: 1rem;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        width: auto;
        height: auto;
        margin-bottom: 10px;
        margin-left: 0.5rem;
    }

    .swiper-pagination {
        position: relative;
    }

    .swiper-pagination-bullet {
        background: #0096ff;
    }
`;

const Review = ({ selectedRegionId, selectedActivity }) => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Fetch reviews from Firestore where the region matches the selectedRegionId and the activity matches selectedActivity
    const fetchReviews = useCallback(async () => {
        setLoading(true);
        try {
            const reviewsCollection = collection(db, 'reviews');

            // Query by selectedRegionId and selectedActivity
            const regionQuery = query(
                reviewsCollection,
                where('trip.selectedRegionId', '==', selectedRegionId),
                orderBy('createdAt', 'desc')
            );

            const activityQuery = query(
                reviewsCollection,
                where('trip.selectedActivity', '==', selectedActivity),
                orderBy('createdAt', 'desc')
            );

            // Fetch reviews for both region and activity
            const regionSnapshot = await getDocs(regionQuery);
            const activitySnapshot = await getDocs(activityQuery);

            // Combine the results, and filter out duplicates by checking the `id`
            const combinedReviews = [
                ...regionSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
                ...activitySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            ];

            // Filter out duplicates by creating a map of unique IDs
            const uniqueReviews = combinedReviews.reduce((acc, review) => {
                if (!acc.some(existingReview => existingReview.id === review.id)) {
                    acc.push(review);
                }
                return acc;
            }, []);

            setReviews(uniqueReviews);
        } catch (error) {
            console.error('Failed to fetch reviews:', error);
        } finally {
            setLoading(false);
        }
    }, [selectedRegionId, selectedActivity]);

    const handleReviewClick = (reviewId) => {
        navigate(`/review/${reviewId}`);
    };

    useEffect(() => {
        if (selectedRegionId || selectedActivity) {
            fetchReviews();
        }
    }, [selectedRegionId, selectedActivity, fetchReviews]);

    return (
        <ReviewsContainer>
            <Title>이런 리뷰 참고해보세요</Title>
            {loading ? (
                <p>리뷰 데이터를 불러오는 중...</p>
            ) : (
                <StyledSwiper
                    slidesPerView={'auto'}
                    spaceBetween={15}
                    centeredSlides={false}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                >
                    {reviews.map((review) => (
                        <SwiperSlide key={review.id}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardActionArea>
                                    {review.images && review.images.length > 0 && (
                                        <CardMedia
                                            component="img"
                                            height="120"
                                            image={review.images[0]}  // Use the first image from Firestore
                                            alt={`${review.title} image`}
                                            onClick={() => handleReviewClick(review.id)}
                                        />
                                    )}
                                    <CardContent onClick={() => handleReviewClick(review.id)} style={{ cursor: 'pointer' }}>
                                        <Typography gutterBottom variant="h6" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {review.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {review.content}
                                        </Typography>
                                        <Rating value={review.rating} readOnly size="small" sx={{ marginTop: '0.5rem' }} />
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </SwiperSlide>
                    ))}
                </StyledSwiper>
            )}
        </ReviewsContainer>
    );
};

export default Review;

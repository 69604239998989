import React from 'react';
import styled from 'styled-components';
import Header from '../components/common/Header';
import RandomTour from '../components/RandomTour/RandomTour';
import Footer from '../components/common/Footer';
import ServiceDescription from '../components/RandomTour/ServiceDescription';
import UserStatus from '../components/RandomTour/UserStatus';

// RandomTour, ServiceDescription, UserStatus를 감싸는 컨테이너 스타일 정의
const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto; /* 가운데 정렬 */
`;

const RandomTourPage = () => (
  <>
    <Header isHome={false} pageTitle="랜덤 투어" /> {/* 페이지 제목을 props로 전달 */}

    {/* 스타일 적용된 ContentContainer로 감싸기 */}
    <ContentContainer>
      <RandomTour />
      <ServiceDescription />
      <UserStatus />
    </ContentContainer>

    <Footer />
  </>
);

export default RandomTourPage;

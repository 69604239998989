import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Header from '../components/common/Header';
import ProfileInfo from '../components/Profile/ProfileInfo';
import MyTrips from '../components/Profile/MyTrips';
import MyReviews from '../components/Profile/MyReviews';
//import Coupons from '../components/Profile/Coupons';
import { useAuth } from '../context/AuthContext'; // logout 가져오기
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const ProfileWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0.5rem;
  overflow: hidden;

  @media (max-width: 1200px) {
    padding: 0;
    overflow: hidden;
  }
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const LogoutButton = styled.button`
  width: 100%;
  max-width: 800px;
  padding: 0.8rem;
  margin-top: 0;
  border: none;
  font-size: 14px;
  cursor: pointer;
  background-color: red;
  color: white;
  &:hover {
    opacity: 0.8;
  }

  @media (min-width: 800px) {
    border-radius: 50px;
  }
`;

const Profile = () => {
  const { user, loading, logout } = useAuth(); // logout 가져오기
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && user === null) {
      navigate('/login'); // 로그인되지 않은 상태면 로그인 페이지로 이동
    }
  }, [user, loading, navigate]);

  const handleLogout = async () => {
    try {
      await logout(); // 로그아웃 호출
      navigate('/');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Header pageTitle="프로필" />
      <ProfileWrapper>
        <ProfileInfo />
        <Title>나의 여행</Title>
        <MyTrips />
        <Title>나의 리뷰</Title>
        <MyReviews />
        <ButtonContainer>
          <LogoutButton onClick={handleLogout}>로그아웃</LogoutButton>
        </ButtonContainer>
      </ProfileWrapper>
    </>
  );
};

export default Profile;

import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';

const ToursightsContainer = styled.div`
  background-color: white;
  overflow: hidden;
  max-width: 1200px;
  width: 100%;
  padding: 0;
`;

const Title = styled.h2`
  color: #0096ff;
  text-align: left;
  margin-left: 1rem;
`;

const StyledSwiper = styled(Swiper)`
  .swiper-wrapper {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 1rem;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: auto;
    height: auto;
    margin-bottom: 10px;
  }

  .swiper-pagination {
    position: relative;
  }

  .swiper-pagination-bullet {
    background: #0096ff;
  }
`;

const MatchingToursights = ({ toursightData, loading }) => {
  const navigate = useNavigate();

  const handleCardClick = (id, contenttypeid) => {
    navigate(`/toursight/${id}/${contenttypeid}`);
  };

  return (
    <ToursightsContainer>
      <Title>맞춤형 관광지</Title>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <StyledSwiper
          slidesPerView={'auto'}
          spaceBetween={15}
          centeredSlides={false}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
        >
          {toursightData.map(({ id, title, addr1, firstimage, overview, contenttypeid }) => (
            <SwiperSlide key={id}>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea onClick={() => handleCardClick(id, contenttypeid)}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={firstimage}
                    alt={title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {addr1}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {overview}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </SwiperSlide>
          ))}
        </StyledSwiper>
      )}
    </ToursightsContainer>
  );
};

export default MatchingToursights;

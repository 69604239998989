import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {collection, query, where, getDocs, getDoc, deleteDoc, doc, collectionGroup} from 'firebase/firestore';
import {db} from '../../firebase';
import {useAuth} from '../../context/AuthContext';
import {useNavigate} from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Pagination} from 'swiper/modules';
import Modal from './Modal';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const MyTripsWrapper = styled.div`
    padding-left: 0;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    max-width: 1200px;
    margin: 0 auto;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 2rem;
`;

const DisabledTripOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    font-size: 1rem;
    font-weight: bold;
`;

const HoverableBox = styled(Box)`
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 250px;
  border: 2px dashed #0096ff;
  border-radius: 10px;
  padding: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const MyTrips = ({
                     onSelectTrip = () => {
                     }, showModal = true, disabledTrips = []
                 }) => {
    const {user} = useAuth();
    const [trips, setTrips] = useState([]);
    const [selectedTrip, setSelectedTrip] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTrips = async () => {
            if (user) {
                const tripsCollection = collection(db, 'tourResults');
                const q = query(tripsCollection, where('userID', '==', user.uid));
                const querySnapshot = await getDocs(q);

                const tripsList = await Promise.all(
                    querySnapshot.docs.map(async (tripDoc) => {
                        const tripData = tripDoc.data();

                        // Fetch region logo
                        const regionLogo = await fetchRegionLogo(tripData.selectedRegionId);
                        // Fetch activity logo
                        const activityLogo = await fetchActivityLogo(tripData.selectedActivity);

                        return {
                            id: tripDoc.id,
                            ...tripData,
                            regionLogo,
                            activityLogo,
                        };
                    })
                );

                setTrips(tripsList);
            }
        };

        fetchTrips();
    }, [user]);

    const fetchRegionLogo = async (regionId) => {
        if (regionId) {
            const regionDocRef = doc(db, 'regions', regionId); // 문서 참조 생성
            const regionDoc = await getDoc(regionDocRef); // getDoc() 사용
            if (regionDoc.exists()) {
                const regionData = regionDoc.data();
                return regionData.logo || 'https://via.placeholder.com/400x300';
            }
        }
        return 'https://via.placeholder.com/400x300';
    };

    const fetchActivityLogo = async (selectedActivity) => {
        if (selectedActivity) {
            const activitiesQuery = query(
                collectionGroup(db, "activities"),
                where("ko", "==", selectedActivity)
            );

            const activitiesSnapshot = await getDocs(activitiesQuery);
            if (!activitiesSnapshot.empty) {
                const activityData = activitiesSnapshot.docs[0].data();
                return activityData.image || 'https://via.placeholder.com/400x300';
            }
        }
        return 'https://via.placeholder.com/400x300';
    };

    const handleDelete = async (tripId) => {
        const confirmDelete = window.confirm('정말로 이 여행을 삭제하시겠습니까?');
        if (confirmDelete) {
            try {
                await deleteDoc(doc(db, 'tourResults', tripId));
                setTrips(prevTrips => prevTrips.filter(trip => trip.id !== tripId));
                alert('삭제되었습니다.');
                closeModal();
            } catch (error) {
                console.error('삭제 중 오류가 발생했습니다: ', error);
                alert('삭제 중 오류가 발생했습니다.');
            }
        }
    };

    const handleTripClick = (trip) => {
        if (!disabledTrips.includes(trip.id)) {
            setSelectedTrip(trip);
            onSelectTrip(trip);
            if (showModal) {
                setIsModalOpen(true);
            }
        } else {
            alert('이미 리뷰가 작성된 여행입니다.');
        }
    };

    const handleNavigateToFirstResult = () => {
        if (selectedTrip) {
            navigate('/first-result', {
                state: {
                    fromChallengeRoulette: true,
                    fromRegionRoulette: true,
                    docId: selectedTrip.docId,
                    selectedActivityName: selectedTrip.selectedActivity,
                    selectedRegion: selectedTrip.selectedRegion,
                    selectedRegionId: selectedTrip.selectedRegionId,
                    selectedTheme: selectedTrip.selectedTheme,
                    startDate: selectedTrip.startDate ? selectedTrip.startDate.seconds * 1000 : null,
                    endDate: selectedTrip.endDate ? selectedTrip.endDate.seconds * 1000 : null,
                    numAdults: selectedTrip.numAdults ? selectedTrip.numAdults : 0,
                    numChildren: selectedTrip.numChildren ? selectedTrip.numChildren : 0,
                    fromMyTrips: true,
                }
            });
        }
    };

    const handleNavigateToSummary = () => {
        if (selectedTrip) {
            navigate('/summary', {
                state: {
                    docId: selectedTrip.docId,
                    selectedActivity: selectedTrip.selectedActivity,
                    selectedRegion: selectedTrip.selectedRegion,
                    selectedRegionId: selectedTrip.selectedRegionId,
                    hotelId: selectedTrip.hotelId,
                    hotelName: selectedTrip.hotelName,
                    startDate: selectedTrip.startDate ? selectedTrip.startDate.seconds * 1000 : null,
                    endDate: selectedTrip.endDate ? selectedTrip.endDate.seconds * 1000 : null,
                    numAdults: selectedTrip.numAdults ? selectedTrip.numAdults : 0,
                    numChildren: selectedTrip.numChildren ? selectedTrip.numChildren : 0,
                    fromProfile: true,
                }
            });
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedTrip(null);
    };

    const handleAddTourClick = () => {
    navigate('/random-tour');
  };

    return (
        <MyTripsWrapper>
            {trips.length > 0 ? (
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={15}
                    pagination={{clickable: true}}
                    modules={[Pagination]}
                    style={{padding: '1rem', height: 250}}
                >
                    {trips.map((trip) => (
                        <SwiperSlide key={trip.id} style={{width: '200px', position: 'relative'}}>
                            <Card
                                sx={{
                                    maxWidth: 200,
                                    position: 'relative',
                                    border: selectedTrip?.id === trip.id ? '2px solid #0096ff' : '2px solid transparent', // 선택된 카드의 테두리 색을 변경
                                    transition: 'border 0.3s ease',
                                }}
                            >
                                <CardActionArea onClick={() => handleTripClick(trip)}>
                                    <Box display="flex" justifyContent="space-between">
                                        {/* 왼쪽 이미지 */}
                                        <CardMedia
                                            component="img"
                                            height="100"
                                            image={trip.regionLogo || 'https://via.placeholder.com/150'}
                                            alt="Region Image"
                                            sx={{width: '50%', objectFit: 'contain'}}
                                        />
                                        {/* 오른쪽 이미지 */}
                                        <CardMedia
                                            component="img"
                                            height="100"
                                            image={trip.activityLogo || 'https://via.placeholder.com/150'}
                                            alt="Activity Image"
                                            sx={{width: '50%'}}
                                        />
                                    </Box>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {trip.selectedRegion || '활동 정보 없음'}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {trip.selectedActivity || '지역 정보 없음'}
                                        </Typography>
                                        {trip.startDate && trip.endDate && (
                                            <Typography variant="body3" sx={{whiteSpace: 'nowrap'}}
                                                        color="text.secondary">
                                                {new Date(trip.startDate.seconds * 1000).toLocaleDateString()} ~ {new Date(trip.endDate.seconds * 1000).toLocaleDateString()}
                                            </Typography>
                                        )}
                                    </CardContent>
                                </CardActionArea>
                                {disabledTrips.includes(trip.id) && (
                                    <DisabledTripOverlay>이미 리뷰 작성됨</DisabledTripOverlay>
                                )}
                            </Card>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <HoverableBox onClick={handleAddTourClick}>
        <Typography variant="h6" color="text.secondary" align="center">
          텅~
        </Typography>
        <Typography variant="h6" color="text.secondary" align="center" style={{ marginTop: '10px' }}>
          랜덤 투어 만들러 가기
        </Typography>
      </HoverableBox>
            )}

            <Modal isOpen={isModalOpen} onClose={closeModal} title="여행 상세 정보">
                {selectedTrip && (
                    <>
                        <Typography variant="body1" align="left">
                            <strong>지역:</strong> {selectedTrip.selectedRegion}
                        </Typography>
                        <Typography variant="body1" align="left">
                            <strong>활동:</strong> {selectedTrip.selectedActivity}
                        </Typography>
                        <Typography variant="body1" align="left">
                            <strong>인원:</strong> 성인 {selectedTrip.numAdults}명
                            {selectedTrip.numChildren > 0 && `, 아동 ${selectedTrip.numChildren}명`}
                        </Typography>

                        {selectedTrip.hotelName && (
                            <Typography variant="body1" align="left">
                                <strong>호텔 이름:</strong> {selectedTrip.hotelName}
                            </Typography>
                        )}
                        {selectedTrip.startDate && selectedTrip.endDate && (
                            <Typography variant="body1" align="left" sx={{whiteSpace: 'nowrap'}}>
                                <strong>여행일자:</strong> {new Date(selectedTrip.startDate.seconds * 1000).toLocaleDateString()} ~{' '}
                                {new Date(selectedTrip.endDate.seconds * 1000).toLocaleDateString()}
                            </Typography>
                        )}
                        <Typography variant="body1" align="left">
                            <strong>저장일:</strong> {new Date(selectedTrip.timestamp.seconds * 1000).toLocaleDateString()}
                        </Typography>

                        <ButtonContainer>
                            <Button variant="contained" color="primary" onClick={handleNavigateToFirstResult}>
                                룰렛 결과
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleNavigateToSummary}>
                                여행 요약
                            </Button>
                            <Button variant="contained" color="error" onClick={() => handleDelete(selectedTrip.id)}>
                                삭제
                            </Button>
                        </ButtonContainer>
                    </>
                )}
            </Modal>
        </MyTripsWrapper>
    );

};

export default MyTrips;

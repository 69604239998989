import React, { useState, useEffect } from 'react';

const PasswordInput = ({ onPasswordChange, onConfirmPasswordChange, passwordError }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordValidityMessage, setPasswordValidityMessage] = useState('');
    const [passwordMatchMessage, setPasswordMatchMessage] = useState('');

    useEffect(() => {
        // 정규 표현식: 최소 8자, 대문자, 소문자, 숫자, 특수 문자 포함
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

        onPasswordChange(password);
        onConfirmPasswordChange(confirmPassword);

        // 비밀번호 유효성 검사
        if (password && !passwordRegex.test(password)) {
            setPasswordValidityMessage(
                '최소 8자 이상, 영문자, 숫자 및 특수 문자를 포함해야 합니다.'
            );
        } else {
            setPasswordValidityMessage(''); // 비밀번호가 유효하면 메시지를 초기화
        }

        // 비밀번호와 비밀번호 확인 일치 여부 확인
        if (confirmPassword && password !== confirmPassword) {
            setPasswordMatchMessage('비밀번호가 일치하지 않습니다.');
        } else {
            setPasswordMatchMessage(''); // 비밀번호가 일치하면 메시지를 초기화
        }
    }, [password, confirmPassword, onPasswordChange, onConfirmPasswordChange]);

    return (
        <>
            <div className="signup-inputWrapper">
                <label htmlFor="password" className="signup-label">비밀번호</label>
                <input
                    type="password"
                    id="password"
                    placeholder="비밀번호를 입력하세요"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="signup-input"
                />
                {passwordValidityMessage && <p className="signup-checkMessage error-message">{passwordValidityMessage}</p>}
            </div>
            <div className="signup-inputWrapper">
                <label htmlFor="confirmPassword" className="signup-label">비밀번호 확인</label>
                <input
                    type="password"
                    id="confirmPassword"
                    placeholder="동일 비밀번호를 입력하세요"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    className="signup-input"
                />
                {passwordMatchMessage && <p className="signup-checkMessage error-message">{passwordMatchMessage}</p>}
                {passwordError && <p className="signup-checkMessage">{passwordError}</p>}
            </div>
        </>
    );
};

export default PasswordInput;

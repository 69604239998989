import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { collection, query, where, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import Header from '../common/Header';
import NameEditModal from './NameEditModal';
import BirthdayEditModal from './BirthdayEditModal';
import PasswordEditModal from './PasswordEditModal';
import styled from 'styled-components';

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    padding-top: 40px;
    padding-bottom: 40px;
`;

const InfoWrapper = styled.div`
    background-color: white;
    padding: 2rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    text-align: left;
`;

const InfoItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`;

const Label = styled.span`
    font-weight: bold;
    margin-right: 0.5rem;
`;

const Value = styled.span`
    margin-right: 1rem;
`;

const EditButton = styled.button`
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.4rem 0.8rem;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

const DeleteAccount = styled.p`
    color: red;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 20px;
    &:hover {
        opacity: 0.8;
    }
`;

const Error = styled.p`
    color: red;
    font-size: 14px;
`;

const EditUserInfo = () => {
    const { user } = useAuth();
    const [userInfo, setUserInfo] = useState({ name: '', birthday: '', phone: '' });
    const [editField, setEditField] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const isConfirmed = localStorage.getItem('isConfirmed');
        if (!isConfirmed) {
            alert('잘못된 접근입니다. 홈으로 이동합니다.');
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        const fetchUserData = async () => {
            if (user) {
                try {
                    const q = query(collection(db, 'users'), where('uid', '==', user.uid));
                    const querySnapshot = await getDocs(q);

                    if (!querySnapshot.empty) {
                        const userDoc = querySnapshot.docs[0];
                        setUserInfo({
                            name: userDoc.data().name || '',
                            birthday: userDoc.data().birthday || '',
                            phone: userDoc.data().phone || '',
                        });
                    } else {
                        console.log('No user document found.');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        if (user) {
            fetchUserData();
        }
    }, [user]);

    const handleEdit = (field) => {
        if (field === 'phone') {
            alert('현재 일시적인 장애로 불가합니다.');
            return;
        }
        setEditField(field);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSave = async (newValue) => {
        if (user) {
            try {
                const q = query(collection(db, 'users'), where('uid', '==', user.uid));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const userDoc = querySnapshot.docs[0];
                    const userDocRef = userDoc.ref;

                    await updateDoc(userDocRef, { [editField]: newValue });
                    setUserInfo({ ...userInfo, [editField]: newValue });
                    setIsModalOpen(false);
                } else {
                    console.error('No user document found to update.');
                }
            } catch (error) {
                console.error('Error updating user info:', error);
                setError('정보 업데이트 중 오류가 발생했습니다.');
            }
        }
    };

    const handleDeleteAccount = async () => {
        const confirmDelete = window.confirm('정말로 회원 탈퇴를 진행하시겠습니까? 리뷰데이터는 남아있습니다.');
        if (confirmDelete && user) {
            try {
                // Firestore에서 유저 데이터 삭제
                const q = query(collection(db, 'users'), where('uid', '==', user.uid));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const userDoc = querySnapshot.docs[0];
                    const userDocRef = userDoc.ref;
                    await deleteDoc(userDocRef); // 유저 문서 삭제
                }

                // Firestore에서 /tourResults 컬렉션의 유저 관련 데이터 삭제
                const tourResultsQuery = query(collection(db, 'tourResults'), where('userID', '==', user.uid));
                const tourResultsSnapshot = await getDocs(tourResultsQuery);

                tourResultsSnapshot.forEach(async (docSnapshot) => {
                    await deleteDoc(doc(db, 'tourResults', docSnapshot.id)); // /tourResults 문서 삭제
                });

                // Firebase Authentication에서 유저 삭제
                await auth.currentUser.delete();

                alert('회원 탈퇴가 완료되었습니다.');
                navigate('/'); // 탈퇴 후 홈으로 이동
            } catch (error) {
                console.error('회원 탈퇴 중 오류 발생:', error);
                setError('회원 탈퇴 중 오류가 발생했습니다.');
            }
        }
    };

    if (!user) {
        return <p>Loading user information...</p>;
    }

    return (
        <>
            <Header />
            <PageWrapper>
                <InfoWrapper>
                    <h2>회원 정보</h2>
                    <InfoItem>
                        <Label>이름:</Label>
                        <Value>{userInfo.name}</Value>
                        <EditButton onClick={() => handleEdit('name')}>수정</EditButton>
                    </InfoItem>
                    <InfoItem>
                        <Label>생일:</Label>
                        <Value>{userInfo.birthday}</Value>
                        <EditButton onClick={() => handleEdit('birthday')}>수정</EditButton>
                    </InfoItem>
                    <InfoItem>
                        <Label>휴대폰 번호:</Label>
                        <Value>{userInfo.phone}</Value>
                        <EditButton onClick={() => handleEdit('phone')}>수정</EditButton>
                    </InfoItem>
                    <InfoItem>
                        <Label>비밀번호:</Label>
                         <Value>********</Value>
                            <EditButton onClick={() => handleEdit('password')}>변경</EditButton>
                    </InfoItem>
                    {error && <Error>{error}</Error>}
                </InfoWrapper>
                {/* 회원 탈퇴 링크 */}
                <DeleteAccount onClick={handleDeleteAccount}>
                    회원 탈퇴
                </DeleteAccount>

                {/* Name Edit Modal */}
                <NameEditModal
                    isOpen={editField === 'name' && isModalOpen}
                    onClose={handleCloseModal}
                    onSave={handleSave}
                    currentValue={userInfo.name}
                    userId={user.uid}
                />

                {/* Birthday Edit Modal */}
                <BirthdayEditModal
                    isOpen={editField === 'birthday' && isModalOpen}
                    onClose={handleCloseModal}
                    onSave={handleSave}
                    currentValue={userInfo.birthday}
                    userId={user.uid}
                />

                <PasswordEditModal
                    isOpen={editField === 'password' && isModalOpen}
                    onClose={handleCloseModal}
                />
            </PageWrapper>
        </>
    );
};

export default EditUserInfo;
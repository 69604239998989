import React, { useEffect, useState, useRef, useCallback } from "react";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { db } from "../../firebase";
import Header from "../common/Header";
import { useNavigate, useLocation } from "react-router-dom";
import { Wheel } from "spin-wheel";
import { easeCubicOut } from "d3-ease";
import styles from "./Challenge.module.css";
import InfoBox from "./InfoBox"; // Import the InfoBox component

const Challenge = () => {
  const [themes, setThemes] = useState([]); // State to hold the list of themes
  const [selectedTheme, setSelectedTheme] = useState(null); // State to hold the selected theme
  const [allActivities, setAllActivities] = useState([]); // 모든 활동을 저장하는 상태
  const [wheelActivities, setWheelActivities] = useState([]); // State to hold the activities on the wheel
  const [isSpinning, setIsSpinning] = useState(false); // State to track if the wheel is spinning
  const [isStopping, setIsStopping] = useState(false); // State to track if the wheel is in the process of stopping
  const [prizeNumber, setPrizeNumber] = useState(0); // State to track the prize number
  const [buttonLabel, setButtonLabel] = useState("랜덤 선택 시작"); // State to track button label
  const [showNextButton, setShowNextButton] = useState(false); // State to toggle between "Stop" and "Next" buttons
  const navigate = useNavigate();
  const location = useLocation();
  const { fromRegionRoulette, startDate, endDate, numAdults, numChildren, selectedRegion, selectedRegionId, docId } = location.state || {}; // Extract selectedRegionId from location.state
  const wheelContainerRef = useRef(null); // Reference to the wheel container
  const wheelInstance = useRef(null); // Reference to the wheel instance

  useEffect(() => {
  const fetchThemesAndActivities = async () => {
    try {
      let themeMap = new Map(); // 중복 제거를 위해 Map 사용
      let activitySet = new Set();
      let activityList = [];


      if (selectedRegionId) {
        // Firestore에서 해당 지역 문서를 가져옴
        const regionDocRef = doc(db, "regions", selectedRegionId);
        const regionDoc = await getDoc(regionDocRef);

        if (regionDoc.exists()) {
          const data = regionDoc.data();
          const toursightsReferences = data.Toursights || []; // 지역의 관광지 참조 배열

          // 관광지 문서를 불러오는 비동기 작업
          const toursightPromises = toursightsReferences.map(async (toursightRef) => {
            const toursightDoc = await getDoc(toursightRef);
            if (toursightDoc.exists()) {
              const toursightData = toursightDoc.data();

              // activityCode가 단일 레퍼런스일 수도 있고 배열일 수도 있으므로 처리
              let activityCodes = [];
              if (toursightData.activityCode) {
                if (Array.isArray(toursightData.activityCode)) {
                  // activityCode가 배열일 경우
                  activityCodes = toursightData.activityCode;
                } else {
                  // activityCode가 단일 레퍼런스일 경우
                  activityCodes = [toursightData.activityCode];
                }
              }

              // 각 활동 문서에서 테마와 활동의 `ko` 필드를 중복되지 않게 가져오는 작업
              const activityPromises = activityCodes.map(async (activityRef) => {
                const activityDoc = await getDoc(activityRef);
                if (activityDoc.exists()) {
                  const activityData = activityDoc.data();
                  const themeId = activityRef.parent.parent.id; // 상위 테마 ID 추출

                  // 상위 테마 문서에서 테마의 ko 값 가져오기
                  const themeDocRef = doc(db, "themes", themeId);
                  const themeDoc = await getDoc(themeDocRef);

                  if (themeDoc.exists()) {
                    const themeData = themeDoc.data();

                    // Map을 사용하여 중복 제거
                    if (!themeMap.has(themeId)) {
                      themeMap.set(themeId, { id: themeId, name: themeData.ko });
                    }

                    if (!activitySet.has(activityDoc.id)) {
                      activitySet.add(activityDoc.id);
                      activityList.push({
                        id: activityDoc.id,
                        name: activityData.ko,
                        themeId: themeId,
                      });
                    }
                  }
                }
              });

              await Promise.all(activityPromises); // 모든 활동 불러오기 완료
            }
          });

          await Promise.all(toursightPromises); // 모든 관광지에 대해 비동기 작업 완료
        } else {
          console.error("No such region document!");
        }
      } else {
        // 모든 테마와 활동 데이터를 가져오는 로직
        const themesCollection = collection(db, "themes");
        const themesSnapshot = await getDocs(themesCollection);
        for (const themeDoc of themesSnapshot.docs) {
          const themeData = themeDoc.data();
          const themeId = themeDoc.id;

          themeMap.set(themeId, { id: themeId, name: themeData.ko });

          // 해당 테마의 활동 가져오기
          const activitiesCollection = collection(db, "themes", themeId, "activities");
          const activitiesSnapshot = await getDocs(activitiesCollection);
          for (const activityDoc of activitiesSnapshot.docs) {
            const activityData = activityDoc.data();
            activityList.push({
              id: activityDoc.id,
              name: activityData.ko,
              themeId: themeId,
            });
          }
        }
      }

      setThemes(Array.from(themeMap.values())); // 테마 목록 저장
      setAllActivities(activityList); // 모든 활동 목록 저장
    } catch (error) {
      console.error("Error fetching themes and activities:", error);
    }
  };

  fetchThemesAndActivities();
}, [selectedRegionId]);




  const fetchActivities = (themeId) => {
  setSelectedTheme(themes.find((theme) => theme.id === themeId)); // 선택된 테마 저장
};




  const initializeWheel = useCallback(() => {
    if (wheelContainerRef.current && wheelActivities.length > 0) {
      const wheelProps = {
        items: wheelActivities.map((activity) => ({
          label: activity.name,
          backgroundColor: "#0096FF",
          labelColor: "#FFFFFF",
        })),
        radius: 0.9,
        lineWidth: 3,
        lineColor: "#FFFFFF",
        pointerAngle: 0,
        itemLabelFont: "Arial",
        itemLabelFontSizeMax: 30,
        itemLabelRadius: 0.6,
        itemLabelAlign: "center",
        itemLabelRotation: 0,
        isInteractive: false,
        rotationResistance: 0,
        debug: false,
      };

      if (!wheelInstance.current) {
        wheelInstance.current = new Wheel(wheelContainerRef.current, wheelProps);
      } else {
        wheelInstance.current.init(wheelProps);
      }
    } else if (wheelContainerRef.current && wheelActivities.length === 0) {
      if (wheelInstance.current) {
        wheelInstance.current.remove();
        wheelInstance.current = null;
      }
    }
  }, [wheelActivities]);

  useEffect(() => {
    initializeWheel();
  }, [wheelActivities, initializeWheel]);

  const startSpin = () => {
    if (!isSpinning && wheelActivities.length > 0 && wheelInstance.current) {
      setIsSpinning(true);
      setShowNextButton(false);
      wheelInstance.current.spin(3000);
    }
  };

  const stopSpin = () => {
    if (isSpinning && wheelInstance.current) {
      setIsStopping(true);
      const winningItemIndex = Math.floor(Math.random() * wheelActivities.length);
      const duration = 4000;

      try {
        wheelInstance.current.spinToItem(
          winningItemIndex,
          duration,
          true,
          1,
          1,
          easeCubicOut
        );

        setPrizeNumber(winningItemIndex);

        setTimeout(() => {
          setIsSpinning(false);
          setIsStopping(false);
          setShowNextButton(true);
          alert(`${wheelActivities[winningItemIndex].name} 당첨!`);
        }, duration);
      } catch (error) {
        console.error("Error stopping the wheel:", error);
        setIsSpinning(false);
        setIsStopping(false);
      }
    }
  };

  const handleNextStep = () => {
  if (wheelActivities[prizeNumber]) {
    const selectedActivity = wheelActivities[prizeNumber];
    const theme = themes.find((t) => t.id === selectedActivity.themeId);

    navigate("/first-result", {
      state: {
        docId: docId || null,
        fromChallengeRoulette: true,
        fromRegionRoulette,
        startDate,
        endDate,
        numAdults,
        numChildren,
        selectedRegion,
        selectedRegionId,
        selectedActivityName: selectedActivity.name,
        selectedTheme: theme.name,
      },
    });
  }
};


  const handleActivityClick = (activity) => {
  if (wheelActivities.find((a) => a.id === activity.id)) {
    // Remove from wheel
    setWheelActivities((prev) => prev.filter((a) => a.id !== activity.id));
  } else {
    // Add to wheel
    setWheelActivities((prev) => [...prev, activity]);
  }
};

  return (
    <>
      <Header pageTitle="챌린지 룰렛" />
      <InfoBox
        fromChallengeRoulette
        fromRegionRoulette
        startDate={startDate}
        endDate={endDate}
        numAdults={numAdults}
        numChildren={numChildren}
        selectedRegion={selectedRegion}
      />
      <div className={styles.rouletteContainer}>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={15}
          scrollbar={true}
          modules={[Scrollbar]}
          style={{ maxWidth:'600px', padding:'1rem', width: 'calc(100% - 2rem)' }}
        >
          {themes.map((theme, index) => (
            <SwiperSlide key={index} style={{width:'150px'}}>
              <div
                className={styles.draggableItem}
                style={{
                  border: "2px solid #004B80",
                  backgroundColor: selectedTheme?.id === theme.id ? "#004B80" : "white",
                  color: selectedTheme?.id === theme.id ? "white" : "black",
                  cursor: "pointer",
                  overflow: 'break-word',
                }}
                onClick={() => {
                  fetchActivities(theme.id);
                }}
              >
                {theme.name}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {selectedTheme && (
          <Swiper
            spaceBetween={15}
            slidesPerView={'auto'}
            scrollbar={true}
            modules={[Scrollbar]}
            style={{ maxWidth:'600px', padding:'1rem', width: 'calc(100% - 2rem)'}}
          >
            {allActivities
              .filter((activity) => activity.themeId === selectedTheme.id)
              .map((activity, index) => (
                <SwiperSlide key={index} style={{width:'150px'}}>
                  <div
                    className={styles.draggableItem}
                    style={{
                      border: "2px solid #0096FF",
                      backgroundColor: wheelActivities.find((a) => a.id === activity.id)
                        ? "#0096FF"
                        : "white",
                      color: wheelActivities.find((a) => a.id === activity.id)
                        ? "white"
                        : "black",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleActivityClick(activity);
                    }}
                  >
                    {activity.name}
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        )}

        <div className={styles.indicator}></div>
        <div ref={wheelContainerRef} className={styles.wheelContainer}></div>
      </div>
      <div className={styles.buttonWrapper}>
        <button
          className={`${styles.rouletteButton} ${styles.primary} ${
            isSpinning || wheelActivities.length === 0 ? styles.disabled : ""
          }`}
          onClick={() => {
            startSpin();
            setButtonLabel("흠 다시...");
          }}
          disabled={isSpinning || wheelActivities.length === 0}
        >
          {buttonLabel}
        </button>
        {showNextButton ? (
          <button className={`${styles.rouletteButton} ${styles.secondary}`} onClick={handleNextStep}>
            다음 단계
          </button>
        ) : (
          <button
            className={`${styles.rouletteButton} ${styles.secondary} ${
              !isSpinning || isStopping ? styles.disabled : ""
            }`}
            onClick={stopSpin}
            disabled={!isSpinning || isStopping}
          >
            랜덤 선택 멈춤
          </button>
        )}
      </div>
    </>
  );
};

export default Challenge;

// src/components/common/Modal.js
import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 1rem  0;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  min-width: 300px;
  width: 90%;
  position: relative;
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
    margin-right: 1.3rem;
    margin-left: 1.3rem;
`;

const Button = styled.button`
  background: ${(props) => (props.primary ? '#0096FF' : '#6c757d')};
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background: ${(props) => (props.primary ? '#0056b3' : '#5a6268')};
  }
`;

const Modal = ({ isOpen, onClose, onNeverShow }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <VideoWrapper>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/8R6olUJ2poQ"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </VideoWrapper>
        {/* 버튼 섹션 추가 */}
        <ButtonWrapper>
          <Button onClick={onNeverShow}>다시 보지 않기</Button>
          <Button primary onClick={onClose}>닫기</Button>
        </ButtonWrapper>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaUserCircle, FaBars, FaTimes, FaArrowLeft } from 'react-icons/fa';
import { useAuth } from '../../context/AuthContext';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import './Header.css';

const Header = ({ pageTitle }) => {
  const { user } = useAuth();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 769);
  const [logoUrl, setLogoUrl] = useState(localStorage.getItem('logoUrl')); // 로컬 스토리지에서 로고 URL 가져오기
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleProfileClick = () => {
    if (user) {
      navigate('/profile');
    } else {
      navigate('/login');
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleProductClick = (event) => {
    event.preventDefault(); // 기본 동작(링크 이동) 방지
    alert('아직 준비중입니다.'); // 알람 메시지 출력
  };

  const isHome = location.pathname === '/';

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 769);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // 로고가 로컬 스토리지에 없는 경우 Firebase Storage에서 로고 URL을 가져오기
    const fetchLogo = async () => {
      if (!logoUrl) {
        const storageRef = getStorage();
        const logoRef = ref(storageRef, 'logo/logo.png'); // Firebase Storage 경로
        const url = await getDownloadURL(logoRef);
        setLogoUrl(url);
        localStorage.setItem('logoUrl', url); // 로컬 스토리지에 저장
      }
    };

    fetchLogo();
  }, [logoUrl]); // logoUrl이 없을 때만 가져오기

  return (
    <header className="header-wrapper">
      <div className="logo-or-back">
        {isMobileView && !isHome ? (
          <button className="back-button" onClick={handleBackClick}>
            <FaArrowLeft size={24} color="#004B80" />
          </button>
        ) : (
          <Link to="/" className="home-icon">
            {logoUrl && (
              <img src={logoUrl} alt="Logo" className="logo" />
            )}
          </Link>
        )}
      </div>

      <div className="mobile-title">{pageTitle}</div>

      <nav className="menu">
        <Link to="/random-tour" className="menu-item">랜덤투어</Link>
        <Link to="/review" className="menu-item">리뷰</Link>
        <Link to="/toursight" className="menu-item">관광지</Link>
        {/* 여행상품 링크에 onClick 이벤트 추가 */}
        <Link to="/product" className="menu-item" onClick={handleProductClick}>여행상품</Link>
      </nav>

      <div className="auth">
        <div className="auth-icon" onClick={handleProfileClick}>
          <FaUserCircle size={24} />
        </div>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </div>
      </div>

      {isMobileMenuOpen && (
        <nav className="mobile-menu">
          <Link to="/" className="mobile-menu-item" onClick={toggleMobileMenu}>홈</Link>
          <Link to="/random-tour" className="mobile-menu-item" onClick={toggleMobileMenu}>랜덤투어</Link>
          <Link to="/review" className="mobile-menu-item" onClick={toggleMobileMenu}>리뷰</Link>
          <Link to="/toursight" className="mobile-menu-item" onClick={toggleMobileMenu}>관광지</Link>
          {/* 모바일 메뉴에서도 onClick 이벤트 추가 */}
          <Link to="/product" className="mobile-menu-item" onClick={(event) => {
            event.preventDefault();
            alert('아직 준비중입니다.');
            toggleMobileMenu(); // 모바일 메뉴 닫기
          }}>여행상품</Link>
        </nav>
      )}
    </header>
  );
};

export default Header;

import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';

const BirthdayInput = ({ onBirthdayChange }) => {
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= 1900; i--) {
      years.push(i);
    }
    return years;
  };

  const generateMonthOptions = () => {
    return Array.from({ length: 12 }, (v, k) => k + 1);
  };

  const generateDayOptions = (year, month) => {
    if (!year || !month) return [];
    const daysInMonth = new Date(year, month, 0).getDate();
    return Array.from({ length: daysInMonth }, (v, k) => k + 1);
  };

  useEffect(() => {
    const birthday = `${year || ''}-${month || ''}-${day || ''}`;
    onBirthdayChange(birthday);
  }, [year, month, day, onBirthdayChange]);

  const handleYearChange = (event, newValue) => {
    setYear(newValue);
    if (month && day > new Date(newValue, month, 0).getDate()) {
      setDay(null);
    }
  };

  const handleMonthChange = (event, newValue) => {
    setMonth(newValue);
    if (year && day > new Date(year, newValue, 0).getDate()) {
      setDay(null);
    }
  };

  const handleInputChange = (setter, options) => (event, newInputValue) => {
    const parsedValue = parseInt(newInputValue, 10);
    if (!isNaN(parsedValue) && options.includes(parsedValue)) {
      setter(parsedValue);
    }
  };

  return (
    <div className="signup-inputWrapper">
      <label className="signup-label">생일</label>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Autocomplete
            options={generateYearOptions()}
            getOptionLabel={(option) => option.toString()}
            value={year}
            onChange={handleYearChange}
            onInputChange={handleInputChange(setYear, generateYearOptions())}
            renderInput={(params) => <TextField {...params} label="년도" />}
            fullWidth
            isOptionEqualToValue={(option, value) => option === value || value === null}
          />
        </Grid>

        <Grid item xs={3.5}>
          <Autocomplete
            options={generateMonthOptions()}
            getOptionLabel={(option) => option.toString()}
            value={month}
            onChange={handleMonthChange}
            onInputChange={handleInputChange(setMonth, generateMonthOptions())}
            renderInput={(params) => <TextField {...params} label="월" />}
            fullWidth
            isOptionEqualToValue={(option, value) => option === value || value === null}
          />
        </Grid>

        <Grid item xs={3.5}>
          <Autocomplete
            options={generateDayOptions(year, month)}
            getOptionLabel={(option) => option.toString()}
            value={day}
            onChange={(event, newValue) => setDay(newValue)}
            onInputChange={handleInputChange(setDay, generateDayOptions(year, month))}
            renderInput={(params) => <TextField {...params} label="일" />}
            fullWidth
            isOptionEqualToValue={(option, value) => option === value || value === null}
            disabled={!year || !month}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BirthdayInput;

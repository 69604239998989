import React from "react";
import styled from "styled-components";

// Styled components
const InfoBoxContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  width: auto;
  max-width: 600px;
  margin: 0 auto;
`;

const InfoItem = styled.div`
  margin: 0.5rem 0;
  font-size: 1rem;
  color: #333;
`;

const InfoBox = ({ fromChallengeRoulette, fromRegionRoulette, selectedRegion, selectedActivityName, startDate, endDate, numAdults, numChildren }) => {
  // startDate와 endDate가 Date 객체가 아니면 변환
  const formattedStartDate = startDate instanceof Date ? startDate : new Date(startDate);
  const formattedEndDate = endDate instanceof Date ? endDate : new Date(endDate);

  return (
    <InfoBoxContainer>
      <InfoItem>
        <strong>여행일정:</strong> {formattedStartDate.toLocaleDateString()} ~ {formattedEndDate.toLocaleDateString()}
      </InfoItem>
      <InfoItem>
        <strong>여행인원:</strong> 성인 {numAdults}명
        {numChildren > 0 && <> 어린이 {numChildren}명</>}
      </InfoItem>
      {fromChallengeRoulette && selectedActivityName && (
        <InfoItem>
          <strong>챌린지:</strong> {selectedActivityName}
        </InfoItem>
      )}
      {fromRegionRoulette && selectedRegion && (
        <InfoItem>
          <strong>여행 지역:</strong> {selectedRegion}
        </InfoItem>
      )}
    </InfoBoxContainer>
  );
};

export default InfoBox;

import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import PlaceIcon from '@mui/icons-material/Place';
import CasinoIcon from '@mui/icons-material/Casino';
import InfoIcon from '@mui/icons-material/Info';
import SmartToyIcon from '@mui/icons-material/SmartToy';

export default function ServiceDescriptionTimeline() {
  return (
    <Timeline position="alternate">
      {/* Step 1: 여행 기본 정보 입력 */}
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          Step 1
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <DirectionsWalkIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            여행 기본 정보 입력
          </Typography>
          <Typography>여행 날짜, 인원 등의 기본 정보를 입력하세요.</Typography>
        </TimelineContent>
      </TimelineItem>

      {/* Step 2: 지역 또는 챌린지 선택 */}
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          Step 2
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <PlaceIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            지역 또는 챌린지 선택
          </Typography>
          <Typography>지역 룰렛 또는 챌린지 룰렛중에 선택하세요.</Typography>
        </TimelineContent>
      </TimelineItem>

      {/* Step 3: 선택한 룰렛을 통해 뽑기 */}
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          Step 3
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary" variant="outlined">
            <CasinoIcon />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            선택한 룰렛을 통해 뽑기
          </Typography>
          <Typography>랜덤으로 여행지 또는 챌린지를 선택합니다.</Typography>
        </TimelineContent>
      </TimelineItem>

      {/* Step 4: 추가 여행 정보 안내 */}
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          Step 4
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
          <TimelineDot color="secondary">
            <InfoIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            추가 여행 정보 안내
          </Typography>
          <Typography>선택된 여행지에 대한 추가 정보를 제공합니다.</Typography>
        </TimelineContent>
      </TimelineItem>

      {/* Step 5: GPT를 통한 여행 종합 안내 */}
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          Step 5
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
          <TimelineDot color="primary">
            <SmartToyIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            GPT를 통한 여행 종합 안내
          </Typography>
          <Typography>AI가 종합적으로 여행 계획을 안내합니다.</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Content 스타일
const Content = styled.div`
  display: flex;
  width: 100%; 
  aspect-ratio: 16 / 9; 
  max-height: 600px;
  justify-content: center;
  align-items: center;
`;

// SingleSection 스타일
const SingleSection = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-image: url(${(props) => props.imageUrl}); /* 외부 링크에서 가져온 이미지 사용 */
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.3s ease;

  display: flex; 
  justify-content: center; 
  align-items: center; 

  &:hover {
    transform: scale(1.05);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* 어두운 오버레이 */
  }

  & h2 {
    color: white;
    font-size: 3rem;
    z-index: 1;
    text-align: center;
    margin: 0; /* 추가적인 여백 제거 */
  }
`;

// RandomTour 컴포넌트
const RandomTour = () => {
  const navigate = useNavigate();

  // 외부 이미지 URL
  const imageUrl = 'https://images.unsplash.com/reserve/91JuTaUSKaMh2yjB1C4A_IMG_9284.jpg?q=80&w=870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';

  return (
    <Content>
      <SingleSection imageUrl={imageUrl} onClick={() => navigate('/select-date-people')}>
        <h2>여행 계획<br />시작 하기</h2>
      </SingleSection>
    </Content>
  );
};

export default RandomTour;

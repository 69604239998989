import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  GoogleAuthProvider,
  reauthenticateWithPopup,
} from 'firebase/auth';
import styled from 'styled-components';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const FormWrapper = styled.div`
  background-color: white;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h2`
  margin-bottom: 2rem;
  font-size: 1.3rem;
  font-weight: bold;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  text-align: left;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 14px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.8rem;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  &:hover {
    opacity: 0.8;
  }
`;

const Error = styled.p`
  color: red;
  font-size: 14px;
`;

const PasswordConfirmation = () => {
  const { user } = useAuth();
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // 현재 사용자의 인증 제공자 확인
  const providerId = user.providerData[0]?.providerId;

  const handlePasswordConfirmation = async (e) => {
    e.preventDefault();

    if (providerId === 'password') {
      // 이메일/비밀번호 사용자
      const credential = EmailAuthProvider.credential(user.email, password);
      try {
        await reauthenticateWithCredential(user, credential);
        localStorage.setItem('isConfirmed', 'true');
        navigate('/edit-info');
      } catch (error) {
        setError('비밀번호가 틀렸습니다. 다시 시도해주세요.');
      }
    } else if (providerId === 'google.com') {
      // 구글 로그인 사용자
      const provider = new GoogleAuthProvider();
      try {
        await reauthenticateWithPopup(user, provider);
        localStorage.setItem('isConfirmed', 'true');
        navigate('/edit-info');
      } catch (error) {
        setError('재인증에 실패했습니다. 다시 시도해주세요.');
      }
    } else {
      setError('지원하지 않는 로그인 방식입니다.');
    }
  };

  return (
    <PageWrapper>
      <FormWrapper>
        <Title>개인정보 수정을 위한 확인</Title>
        <form onSubmit={handlePasswordConfirmation}>
          {providerId === 'password' ? (
            // 이메일/비밀번호 사용자에게 비밀번호 입력 폼 제공
            <>
              <InputWrapper>
                <Label htmlFor="password">비밀번호:</Label>
                <Input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="비밀번호를 입력하세요"
                  required
                />
              </InputWrapper>
              <Button type="submit">비밀번호 확인</Button>
            </>
          ) : providerId === 'google.com' ? (
            // 구글 로그인 사용자에게 재인증 버튼 제공
            <Button type="submit">구글로 재인증하기</Button>
          ) : (
            // 그 외의 제공자에 대한 처리
            <p>지원하지 않는 로그인 방식입니다.</p>
          )}
        </form>
        {error && (
          <InputWrapper>
            <Error>{error}</Error>
          </InputWrapper>
        )}
      </FormWrapper>
    </PageWrapper>
  );
};

export default PasswordConfirmation;
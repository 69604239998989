import React, { useEffect, useState, useCallback } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { red } from '@mui/material/colors';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { collection, getDocs, query, where, orderBy, updateDoc, doc, arrayUnion } from 'firebase/firestore';
import { db } from '../../firebase';
import { format } from 'date-fns';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import StarIcon from "@mui/icons-material/Star";

// Firestore에서 사용자 정보 가져오기 함수
const fetchUserByUID = async (userID) => {
  const usersSnapshot = await getDocs(collection(db, 'users'));
  let userData = null;

  usersSnapshot.forEach((doc) => {
    const userDoc = doc.data();
    if (userDoc.uid === userID) {
      userData = {
        name: userDoc.name,
        profilePicture: userDoc.profilePicture || 'https://via.placeholder.com/150', // 프로필 이미지 없을 시 기본 이미지
      };
    }
  });
  return userData;
};

// 스타일링
const EmptyBox = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 2rem);
  height: 300px;
  background-color: #f0f0f0;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  font-size: 1.5rem;
  color: #888;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const RegionsReviewList = ({ selectedRegion }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  // fetchReviews 함수를 useCallback으로 감싸서 메모이제이션 처리
  const fetchReviews = useCallback(async () => {
    setLoading(true);
    try {
      const reviewsCollection = collection(db, 'reviews');
      const q = query(
        reviewsCollection,
        where('trip.selectedRegionId', '==', selectedRegion.id),
        orderBy('likes', 'desc') // 좋아요 순서대로 정렬
      );
      const reviewSnapshot = await getDocs(q);
      const reviewList = await Promise.all(
        reviewSnapshot.docs.map(async (doc) => {
          const reviewData = doc.data();
          const userData = await fetchUserByUID(reviewData.userID);
          return {
            id: doc.id,
            ...reviewData,
            user: userData,
          };
        })
      );
      setReviews(reviewList);
    } catch (error) {
      console.error('리뷰 데이터 가져오기 실패:', error);
    } finally {
      setLoading(false);
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (selectedRegion) {
      fetchReviews();
    }
  }, [selectedRegion, fetchReviews]);

  const handleLike = async (reviewId, currentLikes, likedUsers) => {
    if (!user) {
      alert('로그인이 필요합니다.');
      navigate('/login');
      return;
    }

    if (likedUsers && likedUsers.includes(user.uid)) {
      alert('이미 이 리뷰에 좋아요를 눌렀습니다.');
      return;
    }

    try {
      const reviewRef = doc(db, 'reviews', reviewId);
      await updateDoc(reviewRef, {
        likes: currentLikes + 1,
        likedUsers: arrayUnion(user.uid),
      });
      fetchReviews();
    } catch (error) {
      console.error('좋아요 업데이트 실패:', error);
    }
  };

  // 리뷰 디테일 페이지로 이동
  const handleReviewClick = (reviewId) => {
    navigate(`/review/${reviewId}`);
  };

  // 리뷰 작성 페이지로 이동
  const handleAddReviewClick = () => {
    navigate('/add-review');
  };

  return (
    <div>
      {loading ? (
        <p>리뷰 데이터를 불러오는 중...</p>
      ) : (
        <>
          {reviews.length === 0 ? (
            <EmptyBox onClick={handleAddReviewClick}>
              텅~
              <br />
              <span style={{ fontSize: '1rem', marginTop: '10px' }}>리뷰 작성하러가기</span>
            </EmptyBox>
          ) : (
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={15}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              style={{ padding: '1rem' }}
            >
              {reviews.map((review) => (
                <SwiperSlide key={review.id} style={{ width: '200px' }}>
                  <Card sx={{ maxWidth: 300, marginBottom: '1rem' }}>
                    <CardHeader
                      avatar={
                        review.user ? (
                          <Avatar src={review.user.profilePicture} alt={review.user.name} />
                        ) : (
                          <Avatar sx={{ bgcolor: red[500] }}>
                            {review.title.charAt(0).toUpperCase()}
                          </Avatar>
                        )
                      }
                      title={review.user?.name || 'Unknown User'}
                      subheader={`${review.createdAt ? format(review.createdAt.toDate(), 'yyyy-MM-dd') : '날짜 없음'}`}
                    />
                    {review.images && review.images.length > 0 && (
                      <CardMedia
                        component="img"
                        height="194"
                        image={review.images[0]}
                        alt={review.title}
                        onClick={() => handleReviewClick(review.id)} // 이미지 클릭 시 리뷰 디테일로 이동
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    <CardContent onClick={() => handleReviewClick(review.id)} style={{ cursor: 'pointer' }}>
                      <Typography variant="h6" fontWeight="bold" color="text.primary" sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {review.title}
                      </Typography>
                    </CardContent>
                    <CardActions disableSpacing sx={{ justifyContent: 'space-between' }}>
                      <IconButton
                        aria-label="add to favorites"
                        onClick={(e) => {
                          e.stopPropagation(); // 좋아요 클릭 시 디테일 이동 방지
                          handleLike(review.id, review.likes || 0, review.likedUsers || []);
                        }}
                        disabled={review.likedUsers && review.likedUsers.includes(user?.uid)}
                      >
                        <Badge badgeContent={review.likes || 0} color="primary">
                          <FavoriteIcon />
                        </Badge>
                      </IconButton>
                      <IconButton>
                        {/* 별점 표시 */}
                      <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                        <StarIcon sx={{ color: '#ffd700', marginRight: '4px' }} />
                        {review.rating ? review.rating.toFixed(1) : '0.0'}
                      </Typography>
                      </IconButton>
                    </CardActions>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </>
      )}
    </div>
  );
};

export default RegionsReviewList;

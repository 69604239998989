import React, { useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';

const NameInput = ({ name, setName, setNameCheckMessage = () => {} }) => {
  const [isChecking, setIsChecking] = useState(false);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isNameEmpty, setIsNameEmpty] = useState(true); // 이름이 비어 있는지 여부
  const [isButtonClicked, setIsButtonClicked] = useState(false); // 중복 확인 버튼 클릭 여부

  // DB에서 이름 중복 여부를 확인하는 함수
  const checkDuplicateName = async (inputName) => {
    setIsChecking(true);
    setNameCheckMessage(''); // 초기화

    try {
      const q = query(collection(db, 'users'), where('name', '==', inputName));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setNameCheckMessage('이미 사용 중인 이름입니다.');
        setIsNameValid(false);
      } else {
        setNameCheckMessage('사용 가능한 이름입니다.');
        setIsNameValid(true);
      }
    } catch (error) {
      console.error('이름 중복 확인 중 오류가 발생했습니다.', error);
      setNameCheckMessage('오류가 발생했습니다. 다시 시도해주세요.');
      setIsNameValid(false);
    }

    setIsChecking(false);
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);

    if (!newName) {
      setIsNameEmpty(true); // 이름이 비어 있으면 true로 설정
      setIsNameValid(false); // 이름이 비어 있으면 유효하지 않음
      setNameCheckMessage(''); // 메시지 비우기
      setIsButtonClicked(false); // 중복 확인 버튼 클릭 상태 초기화
      return;
    }

    setIsNameEmpty(false); // 이름이 입력되었으면 false로 설정
    setIsButtonClicked(false); // 중복 확인 버튼 클릭 상태 초기화
    setNameCheckMessage(''); // 중복 확인 메시지 초기화
  };

  const handleCheckButtonClick = () => {
    if (!isNameEmpty) {
      checkDuplicateName(name); // 이름이 비어 있지 않으면 중복 체크 호출
      setIsButtonClicked(true); // 버튼 클릭 상태 업데이트
    }
  };

  return (
    <div className="signup-inputWrapper">
      <label htmlFor="name" className="signup-label">이름</label>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="text"
          id="name"
          placeholder="이름을 입력하세요"
          value={name}
          onChange={handleNameChange}
          required
          className="signup-input"
        />
        <button
          type="button"
          onClick={handleCheckButtonClick}
          className="signup-checkButton"
          disabled={isNameEmpty} // 이름이 비어 있으면 버튼 비활성화
        >
          중복체크
        </button>
      </div>
      {!isNameEmpty && isButtonClicked && ( // 버튼이 클릭된 후에만 메시지 표시
        isChecking ? (
          <p className="signup-checkMessage">이름 중복 확인 중...</p>
        ) : (
          <p
            className="signup-checkMessage"
            style={{ color: isNameValid ? 'blue' : 'red' }}
          >
            {isNameValid ? '사용 가능한 이름입니다.' : '이미 사용 중인 이름입니다.'}
          </p>
        )
      )}
    </div>
  );
};

export default NameInput;

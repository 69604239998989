import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Rating from '@mui/material/Rating';
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from '../../firebase';
import { useAuth } from '../../context/AuthContext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import Box from '@mui/material/Box';

const MyReviewsWrapper = styled.div`
  padding-left: 0;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
`;

const HoverableBox = styled(Box)`
  display: flex;
  margin: 0 1rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 280px;
  border: 2px dashed #0096ff;
  border-radius: 10px;
  padding: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const formatDate = (timestamp) => {
  if (!timestamp) return '날짜 정보 없음';
  const date = new Date(timestamp.seconds * 1000);
  return date.toLocaleDateString();
};

const MyReviews = () => {
  const [reviews, setReviews] = useState([]);
  const { user } = useAuth();
  const navigate = useNavigate();

  // fetchUserReviews를 useCallback으로 메모이제이션
  const fetchUserReviews = useCallback(async () => {
    if (!user) return;

    const reviewsQuery = query(
      collection(db, "reviews"),
      where("userID", "==", user.uid),
      orderBy("createdAt", "desc")
    );
    const querySnapshot = await getDocs(reviewsQuery);

    const userReviews = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    setReviews(userReviews);
  }, [user]);

  useEffect(() => {
    fetchUserReviews();
  }, [fetchUserReviews]);

  // 리뷰 상세 페이지로 이동
  const handleReviewClick = (reviewId) => {
    navigate(`/review/${reviewId}`);
  };

  const handleAddReviewClick = () => {
    navigate(`/add-review`);
  };


  return (
    <MyReviewsWrapper>
      {reviews.length > 0 ? (
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={15}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          style={{ padding: '1rem', height: 280 }}
        >
          {reviews.map((review) => {
            const selectedRegion = review.trip?.selectedRegion || "지역 정보 없음";
            const startDate = formatDate(review.trip?.startDate);
            const endDate = formatDate(review.trip?.endDate);

            return (
              <SwiperSlide key={review.id} style={{ width: '200px', position: 'relative' }}>
                <Card sx={{ maxWidth: 345 }}>
                  <CardActionArea onClick={() => handleReviewClick(review.id)}>
                    <CardMedia
                      component="img"
                      height="120"
                      image={review.images && review.images.length > 0 ? review.images[0] : 'https://firebasestorage.googleapis.com/v0/b/randomtour-8421b.appspot.com/o/assets%2Ffontlogo_narrow.png?alt=media&token=a5ab8567-26ed-4a2b-aefa-78ab8bb1c00c'}   // 이미지가 없을 경우 기본 이미지
                      alt="리뷰 이미지"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div" sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {review.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {selectedRegion}
                      </Typography>
                      <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }} color="text.secondary">
                         {`${startDate} ~ ${endDate}`}
                      </Typography>
                      <Rating value={review.rating} readOnly size="small" sx={{ marginTop: '0.5rem' }} />
                    </CardContent>
                  </CardActionArea>
                </Card>
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : (
        <HoverableBox onClick={handleAddReviewClick}>
          <Typography variant="h6" color="text.secondary" align="center">
            텅~
          </Typography>
          <Typography variant="h6" color="text.secondary" align="center" style={{ marginTop: '0.5rem' }}>
            리뷰 작성하러 가기
          </Typography>
        </HoverableBox>
      )}
    </MyReviewsWrapper>
  );
};

export default MyReviews;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import styled from 'styled-components';
import Modal from './Modal';
import Badge from '@mui/material/Badge';
import AddIcon from '@mui/icons-material/Add'; // MUI 아이콘 추가
import { styled as muiStyled } from '@mui/material/styles'; // MUI의 styled 사용

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;
  width: 100%;
`;

const ProfileDetails = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
  cursor: default;
`;

const ProfileName = styled.h2`
  margin: 0;
  font-size: 1.5rem;
`;

const FileInput = styled.input`
  display: none;
`;

const MoreButton = styled.button`
  margin-right: 1rem;
  border: #007bff 1px solid;
  border-radius: 5px;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  color: #007bff;
  &:hover {
    opacity: 0.8;
  }
`;

const EditButton = styled(MoreButton)`
  margin-left: auto;
  margin-right: 0;
`;

const ModalProfileDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const ModalProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 1rem;
  cursor: pointer;
`;

const ModalProfileName = styled.h2`
  margin: 0;
  font-size: 1.2rem;
  text-align: left;
`;

const UserInfo = styled.div`
  background-color: rgba(97, 120, 138, 0.2);
  padding: 1rem;
  border-radius: 8px;
  text-align: left;
`;

const UserInfoItem = styled.div`
  margin-bottom: 0.5rem;
  color: #333;
`;

// MUI Badge 커스텀 스타일 정의
const StyledBadge = muiStyled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 25, // 오른쪽으로 더 중앙에 가깝게 위치
    bottom: 20, // 하단으로 이동
    width: 25, // 뱃지 크기를 좀 더 키움
    height: 25,
    border: `2px solid ${theme.palette.background.paper}`,
    borderRadius: '50%',
    backgroundColor: '#3f51b5',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // 배지에 마우스 오버 시 클릭 가능한 커서로 변경
  cursor: 'pointer',
}));

const ProfileInfo = () => {
  const { user } = useAuth();
  const [userData, setUserData] = useState(null);
  const [docId, setDocId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const q = query(collection(db, 'users'), where('uid', '==', user.uid));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          setDocId(userDoc.id);
          setUserData(userDoc.data());
        }
      }
    };
    fetchUserData();
  }, [user]);

  const handleEditClick = () => {
    setIsModalOpen(false);
    navigate('/password-confirm');
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageChange = async (e) => {
    if (e.target.files[0] && user && docId) {
      const file = e.target.files[0];
      const imageRef = ref(storage, `profilePictures/${user.uid}`);
      const uploadTask = uploadBytesResumable(imageRef, file);

      uploadTask.on('state_changed',
        () => {},
        (error) => {
          console.error('Upload failed:', error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const userDocRef = doc(db, 'users', docId);
          await updateDoc(userDocRef, { profilePicture: downloadURL });
          setUserData((prev) => ({ ...prev, profilePicture: downloadURL }));
          setIsModalOpen(false);
        }
      );
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <ProfileWrapper>
      {userData && (
        <>
          <ProfileDetails>
            <ProfileImage
              src={userData.profilePicture || 'default-profile.png'}
              alt="Profile"
            />
            <ProfileName>{userData.name}</ProfileName>
          </ProfileDetails>
          <MoreButton onClick={openModal}>더보기</MoreButton>

          <Modal isOpen={isModalOpen} onClose={closeModal} title="프로필 정보">
            <ModalProfileDetails>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={<AddIcon fontSize="small" />}
                onClick={handleImageClick}
              >
                <ModalProfileImage
                  src={userData.profilePicture || 'default-profile.png'}
                  alt="Profile"
                />
              </StyledBadge>
              <div>
                <ModalProfileName>{userData.name}</ModalProfileName>
              </div>
              <EditButton onClick={handleEditClick}>수정하기</EditButton>
            </ModalProfileDetails>
            <FileInput
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
            <UserInfo>
              <UserInfoItem><strong>Email:</strong> {userData.email}</UserInfoItem>
              <UserInfoItem><strong>Phone:</strong> {userData.phone || 'N/A'}</UserInfoItem>
              <UserInfoItem>
                <strong>가입일:</strong>{' '}
                {userData.createdAt
                  ? new Date(userData.createdAt.seconds * 1000).toLocaleDateString()
                  : 'N/A'}
              </UserInfoItem>
            </UserInfo>
          </Modal>
        </>
      )}
    </ProfileWrapper>
  );
};

export default ProfileInfo;

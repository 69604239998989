import React, { useEffect, useState } from 'react';

const KakaoSDKLoader = () => {
  const [kakao, setKakao] = useState(null);

  useEffect(() => {
    const loadKakaoSDK = () => {
      return new Promise((resolve, reject) => {
        if (!window.Kakao) {
          const script = document.createElement('script');
          script.src = 'https://developers.kakao.com/sdk/js/kakao.min.js';
          script.async = true;
          script.onload = () => {
            const kakaoAppKey = process.env.REACT_APP_KAKAO_APP_KEY;
            if (kakaoAppKey) {
              window.Kakao.init(kakaoAppKey);
              resolve(window.Kakao);
            } else {
              reject('Kakao App Key is missing!');
            }
          };
          script.onerror = () => reject('Failed to load Kakao SDK');
          document.head.appendChild(script);
        } else {
          resolve(window.Kakao);
        }
      });
    };

    loadKakaoSDK()
      .then((Kakao) => {
        setKakao(Kakao);
      })
      .catch((err) => {
        console.error('Kakao SDK Load Error:', err); // 에러를 콘솔에 출력
      });
  }, []);

  return null;  // 로딩 되었을 때 아무것도 표시하지 않음
};

export default KakaoSDKLoader;

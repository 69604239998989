import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination } from 'swiper/modules';
import { collection, query, orderBy, getDocs, limit } from 'firebase/firestore';
import { db } from '../../firebase';
import axios from 'axios';

// MUI 아이콘 import
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

const PopularToursContainer = styled.div`
  padding: 0;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden; /* Prevent horizontal scroll */
`;

const Title = styled.h2`
  padding-left: 1rem;
  text-align: left;
  display: flex; /* 아이콘과 텍스트를 같은 줄에 배치 */
  align-items: center;
  cursor: pointer; /* 클릭 가능한 커서 스타일 추가 */
`;

const IconWrapper = styled.div`
  margin-right: 0.5rem; /* 아이콘과 텍스트 간격 */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TourCard = styled.div`
  background-color: white;
  padding-left: 1rem;
  border-radius: 10px;
  text-align: center;
  width: 170px;
  height: 170px;
  box-sizing: border-box;
  text-decoration: none; /* 기본 링크 스타일 제거 */
`;

const TourImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
`;

const TourTitle = styled.h3`
  margin: 0.5rem 0;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: inherit;
  white-space: nowrap; /* 한 줄로 표시 */
  overflow: hidden; /* 넘치는 텍스트 숨김 */
  text-overflow: ellipsis; /* 말줄임표 처리 */
`;

const StyledSwiper = styled(Swiper)`
  .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    height: 220px;
    width: auto;
    padding: 0 10px;
  }

  .swiper-pagination-bullet {
    background: #0096ff;
  }
`;

const PopularTours = () => {
  const [tours, setTours] = useState([]);
  const navigate = useNavigate();

  // 비동기 데이터 fetching 함수
  const fetchPopularTours = async () => {
    try {
      const q = query(collection(db, 'Toursights'), orderBy('rank', 'asc'), limit(10));
      const querySnapshot = await getDocs(q);

      const tourPromises = querySnapshot.docs.map(async (doc) => {
        const contentid = doc.id;

        const response = await axios.get(
          `https://apis.data.go.kr/B551011/KorService1/detailCommon1?serviceKey=${process.env.REACT_APP_SERVICE_KEY}&MobileOS=ETC&MobileApp=AppTest&_type=json&contentId=${contentid}&defaultYN=Y&firstImageYN=Y`
        );

        const tourInfo = response.data?.response?.body?.items?.item?.[0];

        if (tourInfo) {
          return {
            id: contentid,
            title: tourInfo.title || '제목 없음',
            imageUrl: tourInfo.firstimage || 'https://via.placeholder.com/150',
            contenttypeid: tourInfo.contenttypeid || '',
          };
        } else {
          return {
            id: contentid,
            title: '제목 없음',
            imageUrl: 'https://via.placeholder.com/150',
            contenttypeid: '',
          };
        }
      });

      const toursData = await Promise.all(tourPromises);
      setTours(toursData);
    } catch (error) {
      console.error('Error fetching popular tours:', error);
    }
  };

  // useEffect 내부에서 비동기 함수 호출
  useEffect(() => {
    fetchPopularTours();
  }, []);

  return (
    <PopularToursContainer>
      <Title onClick={() => navigate('/toursight')}>
        {/* 아이콘 추가 */}
        <IconWrapper>
          <TravelExploreIcon style={{ fontSize: 30, color: '#0096ff' }} />
        </IconWrapper>
        인기 여행지
      </Title>
      <StyledSwiper style={{zIndex: '0'}}
        slidesPerView={'auto'}
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        {tours.map((tour) => (
          <SwiperSlide key={tour.id} style={{ width: '170px'}}>
            {/* TourCard 클릭 시 navigate를 사용하여 라우팅 */}
            <TourCard onClick={() => navigate(`/toursight/${tour.id}/${tour.contenttypeid}`)}>
              <TourImage src={tour.imageUrl} alt={tour.title} />
              <TourTitle>{tour.title}</TourTitle>
            </TourCard>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </PopularToursContainer>
  );
};

export default PopularTours;

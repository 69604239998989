import React, { useState, useEffect } from 'react';

const PhoneInput = ({ onPhoneChange, setPhoneCheckMessage, onSendVerification }) => {
  const [phonePart1, setPhonePart1] = useState('');
  const [phonePart2, setPhonePart2] = useState('');
  const [phonePart3, setPhonePart3] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [isTimerActive, setIsTimerActive] = useState(false); // 타이머 활성화 상태
  const [timerSeconds, setTimerSeconds] = useState(30); // 남은 시간

  // 30초 타이머 설정 (useEffect 사용)
  useEffect(() => {
    if (isTimerActive && timerSeconds > 0) {
      const timer = setInterval(() => {
        setTimerSeconds((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer); // 컴포넌트가 언마운트되면 타이머 정리
    } else if (timerSeconds === 0) {
      setIsTimerActive(false); // 타이머가 끝나면 다시 버튼 활성화
      setTimerSeconds(30); // 타이머 초기화
    }
  }, [isTimerActive, timerSeconds]);

  const isPhoneValid = phonePart1.length === 3 && (phonePart2.length === 3 || phonePart2.length === 4) && phonePart3.length === 4;

  const handlePhoneChange = () => {
    const formattedPhone = `+82${phonePart1}${phonePart2}${phonePart3}`;
    onPhoneChange(formattedPhone);
  };

  const sendVerificationCode = async () => {
    const fullPhone = `+82${phonePart1}${phonePart2}${phonePart3}`;
    if (isPhoneValid) {
      try {
        const response = await fetch('https://crxq4srly3.execute-api.ap-northeast-2.amazonaws.com/SMS/send', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ to: fullPhone }),
        });

        if (response.ok) {
          setPhoneCheckMessage('SMS 인증 코드가 전송되었습니다.');
          handlePhoneChange();
          setIsCodeSent(true);
          setVerificationMessage('');
          setIsTimerActive(true); // 타이머 시작
        } else {
          const errorData = await response.json();
          setVerificationMessage(`SMS 전송 실패: ${errorData.message}`);
        }
      } catch (error) {
        console.error('SMS 전송 실패:', error);
        setVerificationMessage('SMS 전송에 실패했습니다. 다시 시도해주세요.');
      }
    } else {
      setVerificationMessage('휴대전화 번호를 모두 입력해주세요.');
    }
  };

  const verifyCode = async () => {
    setIsVerifying(true);
    setVerificationMessage('');
    const fullPhone = `+82${phonePart1}${phonePart2}${phonePart3}`;
    try {
      const response = await fetch('https://crxq4srly3.execute-api.ap-northeast-2.amazonaws.com/SMS/verify', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ to: fullPhone, code: verificationCode }),
      });

      if (response.ok) {
        const result = await response.json();
        if (result && result.data && result.data.status === 'approved') {
          setPhoneCheckMessage('인증에 성공했습니다.');
          onSendVerification();
          setVerificationMessage('인증에 성공했습니다.');
        } else {
          setVerificationMessage('인증 코드가 틀렸습니다. 다시 시도해주세요.');
        }
      } else {
        const errorData = await response.json();
        setVerificationMessage(`인증 실패: ${errorData.message}`);
      }
    } catch (error) {
      console.error('코드 인증 실패:', error);
      setVerificationMessage('코드 인증에 실패했습니다. 다시 시도해주세요.');
    } finally {
      setIsVerifying(false);
    }
  };

  return (
      <div className="signup-inputWrapper">
        {/* 휴대전화 입력 필드 */}
        <label htmlFor="phone" className="signup-label">휴대전화 번호</label>
        <div className="signup-phoneWrapper">
          <input
              type="tel"
              id="phonePart1"
              placeholder="010"
              value={phonePart1}
              onChange={(e) => setPhonePart1(e.target.value.replace(/\D/g, ''))}
              required
              className="signup-input signup-phoneInput"
              maxLength={3}
          />
          <span className="separator">-</span>
          <input
              type="tel"
              id="phonePart2"
              placeholder="1234"
              value={phonePart2}
              onChange={(e) => setPhonePart2(e.target.value.replace(/\D/g, ''))}
              required
              className="signup-input signup-phoneInput"
              maxLength={4}
          />
          <span className="separator">-</span>
          <input
              type="tel"
              id="phonePart3"
              placeholder="5678"
              value={phonePart3}
              onChange={(e) => setPhonePart3(e.target.value.replace(/\D/g, ''))}
              required
              className="signup-input signup-phoneInput"
              maxLength={4}
          />
          <button
              type="button"
              className={`signup-checkButton ${!isPhoneValid || isTimerActive ? 'disabled-button' : ''}`}
              onClick={sendVerificationCode}
              disabled={!isPhoneValid || isTimerActive} // 타이머 활성화 중이면 버튼 비활성화
          >
            {isTimerActive ? `${timerSeconds}초 후 재시도` : '문자인증'}
          </button>
        </div>

        {/* 인증 코드 입력 필드 */}
        {isCodeSent && (
            <div className="signup-codeWrapper" style={{ display: 'flex', alignItems: 'center' }}>
              <input
                  type="text"
                  id="verificationCode"
                  placeholder="인증코드를 입력하세요"
                  onChange={(e) => setVerificationCode(e.target.value)}
                  required
                  className="signup-input"
              />
              <button type="Button" className={'signup-checkButton'} onClick={verifyCode} disabled={isVerifying}>
                {isVerifying ? '인증 확인 중...' : '인증 확인'}
              </button>
            </div>
        )}

        {/* 인증 확인 상태 메시지 표시 */}
        {verificationMessage && (
            <p className={`phone-check-message ${verificationMessage.includes('성공') ? 'success-message' : 'error-message'}`}>
              {verificationMessage}
            </p>
        )}
      </div>
  );
};

export default PhoneInput;

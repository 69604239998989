import React, {useEffect, useState, useRef, useMemo} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {collectionGroup, collection, query, where, getDocs, getDoc} from "firebase/firestore";
import 'ol/ol.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import {Fill, Stroke, Style, Circle as CircleStyle} from 'ol/style';
import {getCenter} from 'ol/extent';
import {fromLonLat} from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {Zoom, Control} from 'ol/control';
import Header from "../common/Header";
import {db} from "../../firebase";
import styles from "./Region.module.css";
import InfoBox from "./InfoBox";

const Region = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        fromChallengeRoulette,
        startDate,
        endDate,
        numAdults,
        numChildren,
        selectedActivityName,
        selectedTheme,
        docId
    } = location.state || {};
    const [regions, setRegions] = useState([]);
    const [features, setFeatures] = useState([]);
    const [filteredFeatures, setFilteredFeatures] = useState([]);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [selectedFeatureName, setSelectedFeatureName] = useState(null);
    const [selectedFeatureSigCd, setSelectedFeatureSigCd] = useState(null);
    const [timer, setTimer] = useState(null);
    const [isSelecting, setIsSelecting] = useState(false);
    const [buttonText, setButtonText] = useState('랜덤 선택 시작');
    const [actionButtonText, setActionButtonText] = useState('랜덤 선택 멈춤');
    const initialCenter = useMemo(() => [14274537.781635705, 4554737.908579463], []);
    const initialZoom = 8;
    const mapRef = useRef(null);
    const vectorLayerRef = useRef(null);

    useEffect(() => {
        const fetchRegionsByActivity = async () => {
            try {
                let regionsList = [];

                if (selectedActivityName) {
                    // selectedActivityName과 일치하는 activityID 찾기
                    const activitiesQuery = query(
                        collectionGroup(db, "activities"), // 모든 테마의 activities 컬렉션에서 검색
                        where("ko", "==", selectedActivityName) // ko 값이 selectedActivityName과 일치하는 활동 검색
                    );

                    const activitiesSnapshot = await getDocs(activitiesQuery);

                    if (!activitiesSnapshot.empty) {
                        // Use the first matching activity document
                        const activityDoc = activitiesSnapshot.docs[0];
                        const activityData = activityDoc.data();

                        // Check if the Toursights field is an array or not
                        const toursightsReferences = Array.isArray(activityData.Toursights) ? activityData.Toursights : [];

                        if (toursightsReferences.length > 0) {
                            // Fetch referenced Toursight documents
                            const sigunguCodePromises = toursightsReferences.map(async (toursightRef) => {
                                const toursightDoc = await getDoc(toursightRef);

                                if (toursightDoc.exists()) {
                                    const toursightData = toursightDoc.data();
                                    const sigunguRef = toursightData.sigunguCode;

                                    // Fetch sigunguCode reference data
                                    const sigunguDoc = await getDoc(sigunguRef);
                                    if (sigunguDoc.exists()) {
                                        return {
                                            id: sigunguDoc.id,
                                            ...sigunguDoc.data(),
                                        };
                                    }
                                }
                                return null;
                            });

                            regionsList = (await Promise.all(sigunguCodePromises)).filter((region) => region !== null);

                            // Remove duplicates based on id
                            const uniqueRegionsList = regionsList.filter(
                                (region, index, self) => index === self.findIndex((r) => r.id === region.id)
                            );

                            regionsList = uniqueRegionsList;
                        } else {
                            console.warn("Toursights field is empty or missing, fetching all regions.");
                            const regionsQuery = collection(db, "regions");
                            const querySnapshot = await getDocs(regionsQuery);
                            regionsList = querySnapshot.docs.map((doc) => ({
                                id: doc.id,
                                ...doc.data(),
                            }));
                        }
                    } else {
                        console.error("No activity found matching selectedActivityName");
                    }

                } else {
                    // selectedActivityName이 없을 경우 모든 지역을 가져옴
                    const regionsQuery = collection(db, "regions");
                    const querySnapshot = await getDocs(regionsQuery);
                    regionsList = querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                }

                setRegions(regionsList);
                initializeMap(regionsList);
            } catch (error) {
                console.error("Error fetching regions by activity:", error);
            }
        };


        const initializeMap = (regionsList) => {
            if (mapRef.current) {
                return;
            }

            const baseLayer = new TileLayer({
                source: new XYZ({
                    url: `https://api.vworld.kr/req/wmts/1.0.0/${process.env.REACT_APP_VWORLD_API_KEY}/white/{z}/{y}/{x}.png`,
                    crossOrigin: 'anonymous',
                }),
            });

            const view = new View({
                center: initialCenter,
                zoom: initialZoom,
                projection: 'EPSG:3857',
            });

            const vectorSource = new VectorSource();
            const vectorLayer = new VectorLayer({
                source: vectorSource,
                style: new Style({
                    stroke: new Stroke({
                        color: '#00d0ff',
                        width: 2,
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 0, 0, 0.1)',
                    }),
                }),
            });

            const mapInstance = new Map({
                target: 'map',
                layers: [baseLayer, vectorLayer],
                view: view,
                controls: [
                    new Zoom({
                        className: 'custom-zoom-control', // Custom class for Zoom control
                        duration: 250,
                    }),
                ],
            });

             fetch('https://firebasestorage.googleapis.com/v0/b/randomtour-8421b.appspot.com/o/assets%2FGangwon.geojson?alt=media&token=65e1b29a-1602-4226-8010-cf0c21b7433a')
            .then((response) => response.json())
            .then((data) => {
                const geojsonSource = new GeoJSON().readFeatures(data, {
                    dataProjection: 'EPSG:3857',
                    featureProjection: 'EPSG:3857',
                });
                vectorSource.addFeatures(geojsonSource);
                setFeatures(geojsonSource);

                let filtered = geojsonSource;
                if (regionsList) {
                    filtered = geojsonSource.filter((feature) => {
                        const regionId = feature.get('SIG_CD');
                        return regionsList.some((region) => region.id === regionId);
                    });
                }

                setFilteredFeatures(filtered);

                const extent = vectorSource.getExtent();
                const calculatedCenter = getCenter(extent);
                mapInstance.getView().setCenter(calculatedCenter);
            })
            .catch((error) => {
                console.error('Error loading GeoJSON data from Firebase:', error);
            });

            mapRef.current = mapInstance;
            vectorLayerRef.current = vectorLayer;

            const myLocationButton = document.createElement('button');
            myLocationButton.innerHTML = '📍';
            myLocationButton.className = 'my-location-button';

            myLocationButton.addEventListener('click', handleMyLocationClick);

            const myLocationControl = new Control({
                element: myLocationButton,
            });

            mapInstance.addControl(myLocationControl);
        };

        fetchRegionsByActivity();
    }, [initialCenter, selectedActivityName]);

    const resetFeatureStyles = () => {
        features.forEach((feature) => {
            feature.setStyle(
                new Style({
                    stroke: new Stroke({
                        color: '#00d0ff',
                        width: 2,
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 0, 0, 0.1)',
                    }),
                })
            );
        });
    };

    const highlightFeature = (feature) => {
        feature.setStyle(
            new Style({
                stroke: new Stroke({
                    color: '#00d0ff',
                    width: 3,
                }),
                fill: new Fill({
                    color: 'rgba(0, 208, 255, 0.3)',
                }),
            })
        );
    };

    const selectRandomRegion = () => {
        if (filteredFeatures.length === 0) return;

        resetFeatureStyles();

        const randomIndex = Math.floor(Math.random() * filteredFeatures.length);
        const selected = filteredFeatures[randomIndex];
        const sigEng = selected.get('SIG_ENG_NM');

        if (!sigEng) {
            console.error('Selected feature SIG_ENG is undefined');
            return;
        }

        highlightFeature(selected);

        setSelectedFeature(selected);
        setSelectedFeatureName(selected.get('SIG_KOR_NM'));
        setSelectedFeatureSigCd(selected.get('SIG_CD'));
    };

    const startRandomSelection = () => {
        if (isSelecting) return;

        setIsSelecting(true);
        setButtonText('흠... 다시!');
        setActionButtonText('랜덤 선택 멈춤');
        resetFeatureStyles();

        if (mapRef.current) {
            mapRef.current.getView().setCenter(initialCenter);
            mapRef.current.getView().setZoom(initialZoom);
        }

        const selectionInterval = setInterval(selectRandomRegion, 200);
        setTimer(selectionInterval);
    };

    const stopRandomSelection = async () => {
        if (!isSelecting) return;

        clearInterval(timer);
        setTimer(null);
        setIsSelecting(false);
        setActionButtonText('여기로 하죠!');

        if (selectedFeature) {
            const extent = selectedFeature.getGeometry().getExtent();
            mapRef.current.getView().fit(extent, {duration: 1000});
            const finalStyle = new Style({
                stroke: new Stroke({
                    color: '#0000FF',
                    width: 3,
                }),
                fill: new Fill({
                    color: 'rgba(0, 0, 255, 0.5)',
                }),
            });
            selectedFeature.setStyle(finalStyle);
        }
    };

    const handleNextStep = () => {
        if (selectedFeature) {
            navigate("/first-result", {
                state: {
                    docId: docId || null,
                    fromRegionRoulette: true,
                    fromChallengeRoulette,
                    startDate,
                    endDate,
                    numAdults,
                    numChildren,
                    selectedRegion: selectedFeatureName,
                    selectedRegionId: selectedFeatureSigCd,
                    selectedActivityName: selectedActivityName,
                    selectedTheme: selectedTheme,
                },
            });
        }
    };


    // 내 위치 버튼 핸들러
    const handleMyLocationClick = () => {
        if (!navigator.geolocation) {
            alert('Geolocation is not supported by your browser.');
            return;
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const {latitude, longitude} = position.coords;
                const coords = [longitude, latitude];

                // EPSG:3857로 변환
                const [x, y] = fromLonLat(coords, 'EPSG:3857');

                if (mapRef.current) {
                    mapRef.current.getView().animate({
                        center: [x, y],
                        zoom: 12,
                        duration: 1000,
                    });
                }

                // 마커 추가
                const markerFeature = new Feature({
                    geometry: new Point([x, y]),
                });

                const markerStyle = new Style({
                    image: new CircleStyle({
                        radius: 6,
                        fill: new Fill({
                            color: 'rgba(0, 0, 255, 0.8)',
                        }),
                        stroke: new Stroke({
                            color: '#fff',
                            width: 2,
                        }),
                    }),
                });

                markerFeature.setStyle(markerStyle);
                vectorLayerRef.current.getSource().addFeature(markerFeature);
            },
            (error) => {
                console.error('Error retrieving location:', error);
            }
        );
    };

    return (
        <>
            <Header pageTitle="지역 룰렛" />
            <InfoBox
                fromChallengeRoulette
                startDate={startDate}
                endDate={endDate}
                numAdults={numAdults}
                numChildren={numChildren}
                selectedActivityName={selectedActivityName}
            />
            <div className={styles.container}>
                <div id="map" className={styles.map}></div>
                <div className={styles.buttonWrapper}>
                    <button
                        className={`${styles.Button} ${styles.primary} ${
                            isSelecting ? styles.disabled : ""
                        }`}
                        onClick={startRandomSelection}
                        disabled={isSelecting || filteredFeatures.length === 0}
                    >
                        {buttonText}
                    </button>
                    <button
                        className={`${styles.Button} ${styles.secondary} ${
                            !isSelecting && !selectedFeature ? styles.disabled : ""
                        }`}
                        onClick={!isSelecting ? handleNextStep : stopRandomSelection}
                        disabled={!isSelecting && !selectedFeature}
                    >
                        {actionButtonText}
                    </button>
                </div>
            </div>
        </>
    );
};

export default Region;

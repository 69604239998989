import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Header from '../common/Header';
import './FirstResult.css';
import {useAuth} from '../../context/AuthContext';
import {db} from "../../firebase";
import {doc, getDoc, collectionGroup, query, where, getDocs} from 'firebase/firestore';

const loadKakaoSDK = () => {
    return new Promise((resolve, reject) => {
        if (!window.Kakao) {
            const script = document.createElement('script');
            script.src = 'https://developers.kakao.com/sdk/js/kakao.min.js';
            script.async = true;
            script.onload = () => {
                const kakaoAppKey = process.env.REACT_APP_KAKAO_APP_KEY;
                if (kakaoAppKey) {
                    window.Kakao.init(kakaoAppKey);
                    resolve(window.Kakao);
                } else {
                    reject('Kakao App Key is missing!');
                }
            };
            script.onerror = () => reject('Failed to load Kakao SDK');
            document.head.appendChild(script);
        } else {
            resolve(window.Kakao);
        }
    });
};

const FirstResult = () => {
    const {user} = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const selectedTheme = location.state?.selectedTheme || null;
    const [selectedRegionId, setSelectedRegionId] = useState(location.state?.selectedRegionId || null);
    const [fromChallengeRoulette, setFromChallengeRoulette] = useState(location.state?.fromChallengeRoulette || false);
    const [fromRegionRoulette, setFromRegionRoulette] = useState(location.state?.fromRegionRoulette || false);
    const [selectedRegion, setSelectedRegion] = useState(location.state?.selectedRegion || null);
    const [selectedActivity, setSelectedActivity] = useState(location.state?.selectedActivityName || null);
    const [startDate, setStartDate] = useState(location.state?.startDate || null);
    const [endDate, setEndDate] = useState(location.state?.endDate || null);
    const [numAdults, setNumAdults] = useState(location.state?.numAdults || 0);
    const [numChildren, setNumChildren] = useState(location.state?.numChildren || 0);
    const [docId, setDocId] = useState(location.state?.docId || null);
    const [logoUrl, setLogoUrl] = useState('https://via.placeholder.com/150'); // 기본 이미지 설정
    const [loading, setLoading] = useState(true);
    const [activityLogo, setActivityLogo] = useState('https://via.placeholder.com/150'); // 기본 이미지 설정


    useEffect(() => {
        loadKakaoSDK()
            .then((Kakao) => {
                // Kakao SDK 로드
            })
            .catch(error => console.error(error));

        const searchParams = new URLSearchParams(location.search);

        // 한글 또는 특수문자를 디코딩하여 사용
        const region = searchParams.get('selectedRegion');
        const activity = searchParams.get('selectedActivity');

        const parsedRegion = region === "null" || region === null ? null : decodeURIComponent(region);
        const parsedActivity = activity === "null" || activity === null ? null : decodeURIComponent(activity);

        if (!selectedRegion && parsedRegion) {
            setSelectedRegion(parsedRegion);
            setFromRegionRoulette(parsedRegion ? true : false);
        }

        if (!selectedActivity && parsedActivity) {
            setSelectedActivity(parsedActivity);
            setFromChallengeRoulette(parsedActivity ? true : false);
        }

        if (!selectedRegionId) {
            setSelectedRegionId(searchParams.get('selectedRegionId'));
        }
        if (!startDate) {
            setStartDate(searchParams.get('startDate'));
        }
        if (!endDate) {
            setEndDate(searchParams.get('endDate'));
        }
        if (!numAdults) {
            setNumAdults(Number(searchParams.get('numAdults')));
        }
        if (!numChildren) {
            setNumChildren(Number(searchParams.get('numChildren')));
        }
        if (!docId) {
            setDocId(searchParams.get('docId'));
        }
    }, [location.search, docId, endDate, numAdults, numChildren, selectedActivity, selectedRegion, selectedRegionId, startDate]);

    useEffect(() => {
        const fetchRegionLogo = async () => {
            if (selectedRegionId) {
                try {
                    const regionDocRef = doc(db, 'regions', selectedRegionId);
                    const regionDoc = await getDoc(regionDocRef);

                    if (regionDoc.exists()) {
                        const regionData = regionDoc.data();
                        if (regionData.logo) {
                            setLogoUrl(regionData.logo); // Firestore에서 가져온 logo URL 설정
                        }
                    } else {
                        console.log("해당 지역 문서가 존재하지 않습니다.");
                    }
                } catch (error) {
                    console.error("지역 로고 데이터를 가져오는 중 오류가 발생했습니다:", error);
                }
                setLoading(false);
            }
        };

        fetchRegionLogo();
    }, [selectedRegionId]); // selectedRegionId가 변경될 때마다 로고를 다시 불러옴

    useEffect(() => {
        const fetchActivityLogo = async () => {
            if (selectedActivity) {
                try {
                    // Firestore query using collectionGroup to search all 'activities' subcollections
                    const activitiesQuery = query(
                        collectionGroup(db, "activities"), // 모든 'activities' 서브컬렉션을 대상으로 검색
                        where("ko", "==", selectedActivity) // 'ko' 필드가 selectedActivity와 일치하는 항목을 찾음
                    );

                    const activitiesSnapshot = await getDocs(activitiesQuery);

                    if (!activitiesSnapshot.empty) {
                        activitiesSnapshot.forEach((doc) => {
                            const activityData = doc.data();
                            const activityImage = activityData.image || 'https://via.placeholder.com/400x300';
                            setActivityLogo(activityImage); // 가져온 이미지로 업데이트
                        });
                    } else {
                        console.log('선택한 액티비티에 해당하는 이미지가 없습니다.');
                    }
                } catch (error) {
                    console.error('액티비티 이미지를 가져오는 중 오류 발생:', error);
                }
                setLoading(false);
            }
        };

        fetchActivityLogo();
    }, [selectedActivity]); // selectedActivity가 변경될 때마다 실행


    const handleShareKakao = () => {
        if (window.Kakao && window.Kakao.Link) {
            // selectedRegion 및 selectedActivity가 null일 경우 해당 값을 query에 포함하지 않도록 처리
            const queryParamsObj = {
                selectedRegion: selectedRegion ? encodeURIComponent(selectedRegion) : '',
                selectedActivity: selectedActivity ? encodeURIComponent(selectedActivity) : '',
                selectedRegionId,
                selectedTheme,
                startDate,
                endDate,
                numAdults,
                numChildren,
                docId,
            };

            // queryParamsObj에서 빈 값은 제외하고 URLSearchParams를 생성
            const queryParams = new URLSearchParams(
                Object.fromEntries(
                    Object.entries(queryParamsObj).filter(([key, value]) => value) // 빈 값은 제외
                )
            ).toString();

            const shareUrl = `https://random.tours/first-result/?${queryParams}`;

            // description에 selectedRegion 또는 selectedActivity가 null일 경우 포함하지 않도록 처리
            const description = `랜덤투어를 통해 나온 결과입니다!\n`
                + (selectedRegion ? `여행지: ${selectedRegion}` : '')
                + (selectedRegion && selectedActivity ? ', ' : '') // 둘 다 있으면 쉼표 추가
                + (selectedActivity ? `활동: ${selectedActivity}` : '');

            window.Kakao.Link.sendDefault({
                objectType: 'feed',
                content: {
                    title: '랜덤투어 결과',
                    description: description,
                    imageUrl: 'https://firebasestorage.googleapis.com/v0/b/randomtour-8421b.appspot.com/o/logo%2Flogo_Icon.png?alt=media&token=14a1da7c-2c21-4911-ab1f-24fac1f82b59',
                    link: {
                        mobileWebUrl: shareUrl,
                        webUrl: shareUrl,
                    },
                },
                buttons: [
                    {
                        title: '결과 보기',
                        link: {
                            mobileWebUrl: shareUrl,
                            webUrl: shareUrl,
                        },
                    },
                ],
            });
        } else {
            alert('Kakao SDK가 초기화되지 않았거나, Kakao.Link 객체가 준비되지 않았습니다.');
        }
    };


    const InfoBox = ({startDate, endDate, numAdults, numChildren}) => {
        return (
            <div className="infoBoxContainer">
                <div className="infoItem">
                    <strong>여행일정:</strong> {new Date(startDate).toLocaleDateString()} ~ {new Date(endDate).toLocaleDateString()}
                </div>
                <div className="infoItem">
                    <strong>여행인원:</strong> 성인 {numAdults}명
                    {numChildren > 0 && (
                        <> 어린이 {numChildren}명</>
                    )}
                </div>
            </div>
        );
    };

    const handleShareTwitter = () => {
        const text = `랜덤투어를 통해 나온 결과입니다!\n여행지: ${selectedRegion}, 활동: ${selectedActivity}`;
        const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent('https://random.tours/')}`;
        window.open(url, '_blank');
    };

    const handleShareInstagram = () => {
        const url = `https://www.instagram.com/?url=${encodeURIComponent('https://random.tours/')}`;
        window.open(url, '_blank');
    };

    const handleShare = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleNextStep = () => {
        if (fromChallengeRoulette && fromRegionRoulette) {
            if (!user) {
                alert('로그인이 필요합니다.');
                navigate('/login');
                return;
            }

            if (new Date(startDate).getTime() === new Date(endDate).getTime()) {
                // 최종 안내 페이지로 이동
                navigate('/summary', {
                    state: {
                        docId,
                        selectedTheme,
                        selectedActivity,
                        selectedRegion,
                        selectedRegionId,
                        startDate,
                        endDate,
                        numAdults,
                        numChildren,
                    },
                });
            } else {
                // 숙소 정하기 페이지로 이동
                navigate('/search-accommodations', {
                    state: {
                        docId,
                        selectedTheme,
                        selectedActivity,
                        selectedRegion,
                        selectedRegionId,
                        startDate,
                        endDate,
                        numAdults,
                        numChildren,
                    },
                });
            }
        } else if (fromRegionRoulette) {
            navigate('/challenge', {
                state: {
                    docId,
                    fromRegionRoulette,
                    selectedRegion,
                    selectedRegionId,
                    startDate,
                    endDate,
                    numAdults,
                    numChildren,
                },
            });
        } else if (fromChallengeRoulette) {
            navigate('/region', {
                state: {
                    docId,
                    fromChallengeRoulette,
                    selectedActivityName: selectedActivity,
                    selectedTheme,
                    startDate,
                    endDate,
                    numAdults,
                    numChildren,
                },
            });
        }
    };


    const handleChangeActivity = () => {
        navigate('/challenge', {
            state: {
                docId,
                fromRegionRoulette,
                selectedRegion: selectedRegion,
                selectedRegionId: selectedRegionId,
                startDate,
                endDate,
                numAdults,
                numChildren,
            }
        });
    };

    const handleChangeRegion = () => {
        navigate('/region', {
            state: {
                docId,
                fromChallengeRoulette,
                selectedActivityName: selectedActivity,
                selectedTheme: selectedTheme,
                startDate,
                endDate,
                numAdults,
                numChildren,
            }
        });
    };

    return (
        <>
            <Header pageTitle="룰렛 결과"/>
            <div className="result-container">
                <InfoBox
                    startDate={startDate}
                    endDate={endDate}
                    numAdults={numAdults}
                    numChildren={numChildren}
                />

                {/* 장소와 활동이 모두 선택되었을 때 모두 보여줌 */}
                {fromRegionRoulette && (
                    <div className="section">
                        <div className="section-left">
                            <h2>가는 곳</h2>
                            <p>{selectedRegion}</p>
                            <button className="changeButton" onClick={handleChangeRegion}>
                                다시 정하기
                            </button>
                        </div>
                        <div className="section-right">
                            {loading ? (
                                <p>이미지를 불러오는 중...</p> // 로딩 상태 처리
                            ) : (
                                <img src={logoUrl} alt="Region"/>
                            )}
                        </div>
                    </div>
                )}

                {fromChallengeRoulette && (
                    <div className="section">
                        <div className="section-left">
                            <h2>챌린지</h2>
                            <p>{selectedActivity}</p>
                            <button className="changeButton" onClick={handleChangeActivity}>
                                다시 정하기
                            </button>
                        </div>
                        <div className="section-right">
                            {loading ? (
                                <p>이미지를 불러오는 중...</p> // 로딩 상태 처리
                            ) : (
                                <img src={activityLogo} alt="Activity"/>
                            )}
                        </div>
                    </div>
                )}

                {/* 하단 버튼 섹션 */}
                <div className="buttonWrapper">
                    <button className="shareButton" onClick={handleShare}>일행과 공유하기</button>
                    <button className="nextButton" onClick={handleNextStep}>
                        {(fromChallengeRoulette && fromRegionRoulette)
                            ? (new Date(startDate).getTime() === new Date(endDate).getTime() ? '최종 안내 페이지' : '숙소 정하기')
                            : (fromChallengeRoulette ? '장소 정하기' : '챌린지 정하기')}
                    </button>
                </div>
            </div>

            {isModalOpen && (
  <div className="modalOverlay">
    <div className="modalContent">
      <div className="modalHeader">
        <h2>일행과 공유하기</h2>
        <button className="closeModal" onClick={handleCloseModal}>X</button>
      </div>
      <div className="modalBody">
        <img src="assets/kakao-logo.png" alt="Kakao" onClick={handleShareKakao} />
        <img src="assets/instagram-logo.svg" alt="Instagram" onClick={handleShareInstagram} />
        <img src="assets/x.svg" alt="Twitter" onClick={handleShareTwitter} />
      </div>
    </div>
  </div>
)}

        </>
    );
};

export default FirstResult;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import Header from '../../components/common/Header';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL,deleteObject  } from 'firebase/storage';
import { useAuth } from '../../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';

const labels = {
  0.5: '아쉽네',
  1: '미흡해요',
  1.5: '부족해요',
  2: '개선 필요',
  2.5: '그저 그럼',
  3: '쏘쏘',
  3.5: '괜찮네',
  4: '훌륭하네',
  4.5: '대박',
  5: '왕대박',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

const PageWrapper = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
`;

const TitleInput = styled.input`
  width: calc(100% - 10px);
  padding: 5px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  height: 40px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const TextInput = styled.textarea`
  width: calc(100% - 20px);
  height: 150px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  resize: none;
`;

const ImageUploadSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ImageUploadBox = styled.div`
  width: 100px;
  height: 100px;
  margin-bottom: 2rem;
  border: 2px dashed #ccc;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    border-color: #0096ff;
  }

  &::before {
    content: '+';
    font-size: 48px;
    color: #ccc;
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const ImagePreview = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 10px;

  img {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    object-fit: cover;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    background: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const ImagePreviewContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
  padding: 10px 0;
`;

const SubmitButton = styled.button`
  padding: 1rem 1.5rem;
  border: none;
  font-size: 0.8rem;
  cursor: pointer;
  background-color: #0096FF;
  color: white;
  width: 100%;
  max-width: 800px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 1rem;

  &:disabled {
    background-color: #ccc;
  }
`;

const EditReviewPage = () => {
  const { reviewId: paramReviewId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const reviewId = location.state?.reviewId || paramReviewId;
  const { user } = useAuth();
  const [value, setValue] = useState(0);
  const [hover, setHover] = useState(-1);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [imageURLs, setImageURLs] = useState([]);
  const storage = getStorage();
  const [imagePaths, setImagePaths] = useState([]); // Storage 경로 저장
  const [tripID, setTripID] = useState(''); // 리뷰의 tripID 저장
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    const fetchReviewData = async () => {
      if (reviewId && user) { // reviewId와 user가 있을 때만 실행
        const reviewRef = doc(db, 'reviews', reviewId);
        const reviewDoc = await getDoc(reviewRef);
        if (reviewDoc.exists()) {
          const reviewData = reviewDoc.data();
          if (reviewData.userID === user.uid) {
            setIsOwner(true);
            setTitle(reviewData.title);
            setContent(reviewData.content);
            setImagePaths(reviewData.images || []);
            setImageURLs(reviewData.images || []);
            setTripID(reviewData.tripID);
          } else {
            alert('리뷰 수정 권한이 없습니다.');
            navigate('/review'); // 권한이 없으면 리뷰 목록 페이지로 이동
          }
        } else {
          alert('리뷰를 찾을 수 없습니다.');
          navigate('/review'); // 리뷰가 존재하지 않으면 리뷰 목록 페이지로 이동
        }
      } else {
        alert('로그인 후 수정가능합니다')
        navigate('/login');
      }
    };

    fetchReviewData();
  }, [reviewId, user, navigate]);

  const handleRatingChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];

    // 여행 정보가 설정되지 않았을 경우 업로드 중단
    if (!tripID) {
      alert('여행 정보가 설정되지 않았습니다. 여행을 먼저 선택하세요.');
      return;
    }

    if (file && imagePaths.length < 5) {
      try {
        // 파일명을 UUID를 사용하여 고유하게 변경
        const uniqueFileName = `${uuidv4()}_${file.name}`;

        // Firebase Storage 경로 설정 (예: review_images/tripID/고유파일명)
        const storageRef = ref(storage, `review_images/${tripID}/${uniqueFileName}`);

        // 이미지 파일을 Firebase Storage에 업로드
        await uploadBytes(storageRef, file);

        // Storage 경로를 imagePaths에 추가
        const filePath = storageRef.fullPath;
        setImagePaths([...imagePaths, filePath]);

        // 다운로드 URL을 가져와 미리보기 URL로 사용
        const downloadURL = await getDownloadURL(storageRef);
        setImageURLs([...imageURLs, downloadURL]);

      } catch (error) {
        console.error("이미지 업로드 중 오류 발생: ", error);
        alert("이미지 업로드 중 오류가 발생했습니다.");
      }
    } else if (imagePaths.length >= 5) {
      alert('최대 5장까지 업로드할 수 있습니다.');
    }
  };

  const handleImageClick = () => {
    document.getElementById('imageInput').click();
  };

  const handleImageRemove = async (index) => {
    const imagePath = imagePaths[index];
    if (!imagePath) return;

    try {
      // Firebase Storage에서 이미지 삭제
      const imageRef = ref(storage, imagePath);
      await deleteObject(imageRef);

      // 상태 업데이트 - 삭제된 이미지의 경로 및 미리보기 URL 제거
      setImagePaths(imagePaths.filter((_, i) => i !== index));
      setImageURLs(imageURLs.filter((_, i) => i !== index));
    } catch (error) {
      console.error('Firebase Storage에서 이미지 삭제 중 오류 발생:', error);
      alert('이미지 삭제 중 오류가 발생했습니다.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const reviewRef = doc(db, 'reviews', reviewId);
      await updateDoc(reviewRef, {
        title,
        content,
        rating: value,
        images: imageURLs,
        imagesPath: imagePaths,
        updatedAt: new Date(),
      });

      alert('리뷰가 성공적으로 수정되었습니다!');
      navigate('/profile');  // 수정 후 리뷰 목록 페이지로 이동
    } catch (error) {
      console.error('리뷰 수정 중 오류 발생: ', error);
    }
  };

  const isSubmitDisabled = !title || content.replace(/<[^>]+>/g, '').trim().length <= 8 || value === 0;

  return (
    <>
      <Header />
      <PageWrapper>
        <Title>리뷰 수정하기</Title>

        <Section>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <Rating
              name="hover-feedback"
              value={value}
              precision={0.5}
              getLabelText={getLabelText}
              onChange={handleRatingChange}
              onChangeActive={(event, newHover) => setHover(newHover)}
              size="large"
              sx={{ fontSize: '3rem' }}
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            />
            {value !== null && (
              <Box sx={{ mt: 1, fontSize: '1rem', textAlign: 'center' }}>{labels[hover !== -1 ? hover : value]}</Box>
            )}
          </Box>
        </Section>

        <Section>
          <h3>제목</h3>
          <TitleInput
            type="text"
            value={title}
            onChange={handleTitleChange}
            placeholder="제목을 입력하세요..."
          />
        </Section>

        <Section>
          <h3>후기 수정하기</h3>
          <TextInput value={content} onChange={handleContentChange} placeholder="후기를 입력하세요..." />
        </Section>

        <Section>
          <h3>이미지 수정 (최대 5장)</h3>
          <ImageUploadSection>
            <ImagePreviewContainer>
              {imageURLs.map((url, index) => (
                <ImagePreview key={index}>
                  <img src={url} alt={`Uploaded ${index}`} />
                  <button onClick={() => handleImageRemove(index)}>x</button>
                </ImagePreview>
              ))}
              {imageURLs.length < 5 && (
                <ImageUploadBox onClick={handleImageClick}>
                  <HiddenFileInput id="imageInput" type="file" accept="image/*" onChange={handleImageUpload} />
                </ImageUploadBox>
              )}
            </ImagePreviewContainer>
          </ImageUploadSection>
        </Section>

        <SubmitButton onClick={handleSubmit} disabled={isSubmitDisabled}>
          수정완료
        </SubmitButton>
      </PageWrapper>
    </>
  );
};

export default EditReviewPage;

// PasswordEditModal.js

import React, { useState } from 'react';
import { updatePassword, EmailAuthProvider, reauthenticateWithCredential, linkWithCredential } from 'firebase/auth';
import { auth } from '../../firebase';
import styled from 'styled-components';

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

const ModalContent = styled.div`
    background-color: white;
    padding: 2.5rem;
    border-radius: 12px;
    width: 90%;
    max-width: 450px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

const Title = styled.h2`
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #333;
`;

const InputGroup = styled.div`
    margin-bottom: 1.2rem;
`;

const Label = styled.label`
    display: block;
    font-size: 1rem;
    margin-bottom: 0.4rem;
    color: #555;
`;

const Input = styled.input`
    width: calc(100% - 2rem);
    padding: 0.8rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 1rem;
    transition: border-color 0.2s;
    
    &:focus {
        border-color: #007bff;
        outline: none;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
`;

const Button = styled.button`
    padding: 0.8rem 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    background-color: ${(props) => (props.$cancel ? '#e74c3c' : '#007bff')}; /* Red for cancel, blue for save */
    color: white;
    width: 48%;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${(props) => (props.$cancel ? '#c0392b' : '#0056b3')};
    }
`;

const ErrorMessage = styled.p`
    color: red;
    font-size: 0.9rem;
    text-align: center;
`;

const SuccessMessage = styled.p`
    color: green;
    font-size: 0.9rem;
    text-align: center;
`;

const PasswordEditModal = ({ isOpen, onClose }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSave = async () => {
    setError('');
    setSuccessMessage('');

    if (newPassword !== confirmPassword) {
      setError('새 비밀번호가 일치하지 않습니다.');
      return;
    }

    if (newPassword.length < 6) {
      setError('비밀번호는 최소 6자 이상이어야 합니다.');
      return;
    }

    const user = auth.currentUser;

    if (user) {
      const providerId = user.providerData[0]?.providerId;

      if (providerId === 'password') {
        const credential = EmailAuthProvider.credential(user.email, currentPassword);

        try {
          await reauthenticateWithCredential(user, credential);
          await updatePassword(user, newPassword);
          setSuccessMessage('비밀번호가 성공적으로 변경되었습니다.');
          setCurrentPassword('');
          setNewPassword('');
          setConfirmPassword('');
        } catch (error) {
          console.error('비밀번호 변경 오류:', error);
          setError('현재 비밀번호가 틀렸습니다.');
        }
      } else {
        try {
          const credential = EmailAuthProvider.credential(user.email, newPassword);
          await linkWithCredential(user, credential);
          setSuccessMessage('비밀번호가 성공적으로 설정되었습니다.');
          setCurrentPassword('');
          setNewPassword('');
          setConfirmPassword('');
        } catch (error) {
          console.error('비밀번호 설정 오류:', error);
          setError('비밀번호 설정 중 오류가 발생했습니다.');
        }
      }
    }
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <Title>비밀번호 변경</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}

        {auth.currentUser.providerData[0]?.providerId === 'password' && (
          <InputGroup>
            <Label>현재 비밀번호:</Label>
            <Input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </InputGroup>
        )}

        <InputGroup>
          <Label>새 비밀번호:</Label>
          <Input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </InputGroup>

        <InputGroup>
          <Label>새 비밀번호 확인:</Label>
          <Input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </InputGroup>

        <ButtonWrapper>
          <Button onClick={handleSave}>저장</Button>
          <Button $cancel onClick={onClose}>취소</Button>
        </ButtonWrapper>
      </ModalContent>
    </ModalOverlay>
  );
};

export default PasswordEditModal;

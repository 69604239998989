import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination'; // Only import pagination CSS
import { Pagination, Autoplay } from 'swiper/modules'; // Import Autoplay module for automatic sliding
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Firebase Firestore 초기화

// MUI 아이콘 import
import InfoIcon from '@mui/icons-material/Info';

const AnnouncementsContainer = styled.div`
  padding-left: 0;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  max-width: 1200px;
  margin: 0;
`;

const Title = styled.h2`
  padding-left: 1rem;
  color: black;
  text-align: left;
  margin-top: 0;
  display: flex; /* 아이콘과 텍스트를 한 줄에 배치 */
  align-items: center; /* 세로 가운데 정렬 */
`;

const IconWrapper = styled.div`
  margin-right: 0.5rem; /* 아이콘과 텍스트 간격 조절 */
`;

const StyledSwiper = styled(Swiper)`
  z-index: 0;
  height: 50px; /* Swiper 높이 설정 */
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const AnnouncementTitle = styled.h3`
  color: #333;
  font-size: 1rem;
  margin-left: 1rem; /* 왼쪽 패딩 */
  margin-top: 0;
  margin-bottom: 0;
  cursor: ${(props) => (props.hasUrl ? 'pointer' : 'default')}; /* url 유무에 따라 커서 스타일 */
`;

const Announcement = () => {
  const [announcements, setAnnouncements] = useState([]);

  // Firestore에서 공지사항 가져오기
  const fetchAnnouncements = async () => {
    try {
      const announcementCollection = collection(db, 'Announcement'); // 'Announcement' 컬렉션 참조
      const querySnapshot = await getDocs(announcementCollection);
      const announcementList = querySnapshot.docs.map((doc) => ({
        id: doc.id, // Firestore 문서 ID를 사용
        ...doc.data(), // 문서 데이터 확장
      }));
      setAnnouncements(announcementList);
    } catch (error) {
      console.error('Error fetching announcements: ', error);
    }
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  return (
    <AnnouncementsContainer>
      <Title>
        {/* InfoIcon 추가 */}
        <IconWrapper>
          <InfoIcon style={{ fontSize: 30, color: '#0096ff' }} />
        </IconWrapper>
        공지사항
      </Title>
      <StyledSwiper
        direction={'vertical'} // 세로 슬라이드 설정
        spaceBetween={20}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 3000, // 3초 간격으로 자동 슬라이드
          disableOnInteraction: false, // 사용자가 스와이프해도 자동 슬라이드 유지
        }}
        modules={[Pagination, Autoplay]} // Autoplay와 Pagination 모듈 사용
      >
        {announcements.map((announcement) => (
          <SwiperSlide key={announcement.id}>
            {/* url이 있는 경우만 onClick을 설정 */}
            <AnnouncementTitle
              hasUrl={!!announcement.url} // url 존재 여부에 따라 스타일 적용
              onClick={announcement.url ? () => window.open(announcement.url, '_blank') : undefined}
            >
              - {announcement.ko}
            </AnnouncementTitle>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </AnnouncementsContainer>
  );
};

export default Announcement;

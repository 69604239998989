import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import LoginPage from './components/auth/LoginPage';
import SignUpPage from './components/auth/SignUpPage';
import ForgotIDPage from './components/auth/ForgotIDPage';
import ForgotPasswordPage from './components/auth/ForgotPasswordPage';
import GoogleSignUp from './components/auth/GoogleSignUp';
import SignUpComplete from './components/auth/SignUpComplete';
import RandomTourPage from './pages/RandomTourPage';
import ReviewPage from './pages/Review/ReviewPage';
import ReviewDetailPage from './pages/Review/ReviewDetailPage';
import AddReviewPage from './pages/Review/AddReviewPage';
import EditReviewPage from './pages/Review/EditReviewPage';
import ToursightPage from './pages/ToursightPage';
import Detail from './components/Toursights/Detail';
import ProductPage from './pages/ProductPage';
import Profile from './pages/Profile';
import PasswordConfirmation from './components/user/PasswordConfirmation';
import EditUserInfo from './components/user/EditUserInfo';
import Selection from './components/Tourplan/Selection';
import Challenge from './components/Tourplan/Challenge';
import Region from './components/Tourplan/Region';
import FirstResult from './components/Tourplan/FirstResult';
import SelectDatePeople from './components/Tourplan/SelectDatePeople';
import SearchAccommodations from './components/Tourplan/SearchAccommodations';
import Summary from './components/Tourplan/Summary';
import { createGlobalStyle } from 'styled-components';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './context/AuthContext';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }
`;

const googleClientId = process.env.REACT_APP_FIREBASE_API_KEY;

const App = () => (
  <GoogleOAuthProvider clientId={googleClientId}>
    <AuthProvider>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/signup-complete" element={<SignUpComplete />} />
          <Route path="/forgot-id" element={<ForgotIDPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/google-signup" element={<GoogleSignUp />} />
          <Route path="/random-tour" element={<RandomTourPage />} />
          <Route path="/review" element={<ReviewPage />} />
          <Route path="/review/:reviewId" element={<ReviewDetailPage />} />
          <Route path="/add-review" element={<AddReviewPage />} />
          <Route path="/edit-review/:reviewId" element={<EditReviewPage />} />
          <Route path="/toursight" element={<ToursightPage />} />
          <Route path="/toursight/:contentid/:contentTypeId" element={<Detail />} />
          <Route path="/product" element={<ProductPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/password-confirm" element={<PasswordConfirmation />} />
          <Route path="/edit-info" element={<EditUserInfo />} />
          <Route path="/selection" element={<Selection />} />
          <Route path="/challenge" element={<Challenge />} />
          <Route path="/region" element={<Region />} />
          <Route path="/first-result" element={<FirstResult />} />
          <Route path="/select-date-people" element={<SelectDatePeople />} />
          <Route path="/search-accommodations" element={<SearchAccommodations />} />
          <Route path="/summary/:docId?" element={<Summary />} />
        </Routes>
      </Router>
    </AuthProvider>
  </GoogleOAuthProvider>
);

export default App;
